import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialUserState = {
  userList: [],
  exportUrl: null,
  isFetching: false,

  adminUserList: [],
  approvedDetails: null,
  // profileUpdateSuccess: null,
};

const UserContext = createContext({
  ...initialUserState,
  exportUserList: () => Promise.resolve(),
  fetchUserList: () => Promise.resolve(),
  // loggedinProfileUpdate: () => Promise.resolve()
});

const ACTION_TYPES = {
  ISFETCHING: 'ISFETCHING',
  FETCH: 'FETCH',
  EXPORT: 'EXPORT',
  UPDATE: 'UPDATE',
  ADMINUSERFETCH: 'ADMINUSERFETCH',
  APPROVEDROLEFETCH: 'APPROVEDROLEFETCH',
  DELETEFETCH: 'DELETEFETCH',
  // LOGGEDINPROFILEUPDATE: 'LOGGEDINPROFILEUPDATE'
};

const handler = {
  ISFETCHING: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  FETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  EXPORT: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  UPDATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  ADMINUSERFETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },

  APPROVEDROLEFETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  DELETEFETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  // LOGGEDINPROFILEUPDATE: (state, payload) => {
  //   console.log(payload, 'YYYYYYYYYY===>', state)
  //   return {
  //     ...state,
  //     ...payload,
  //    profileUpdateSuccess: payload.profileUpdateSuccess
  //   };
  // }
};

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialUserState);

  const exportUserList = async (startDate, endDate, signal) => {
    const requestData = { startDate, endDate };
    const data = await apiCaller(
      'post',
      '/user/export',
      requestData,
      null,
      signal,
      'arraybuffer'
    );
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const blobURL = URL.createObjectURL(blob);
    dispatch({
      type: ACTION_TYPES.EXPORT,
      payload: {
        exportUrl: blobURL
      }
    });
  };

  const fetchUserList = async (startDate, endDate, signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });

    const requestData = { startDate, endDate };

    try {
      const { data } = await apiCaller(
        'post',
        '/user/fetch',
        requestData,
        null,
        signal
      );
      const { allUsers } = data;
      dispatch({
        type: ACTION_TYPES.FETCH,
        payload: {
          userList: allUsers,
          isFetching: false
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACTION_TYPES.ISFETCHING,
        payload: { isFetching: false }
      });
    }
  };
  const updateUser = async (reqData) => {
    const { success } = await apiCaller('post', '/user/status', reqData);
    dispatch({
      type: ACTION_TYPES.UPDATE,
      payload: {
        isFetching: false
      }
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  // const loggedinProfileUpdate = async (payload) => {
  //   dispatch({
  //     type: ACTION_TYPES.ISFETCHING,
  //     payload: { isFetching: true }
  //   });
  //   try {
  //     const { data, success } = await apiCaller('put', '/update', payload);
  //     dispatch({
  //       type: ACTION_TYPES.LOGGEDINPROFILEUPDATE,
  //       payload: {
  //         isFetching: false,
  //         profileUpdateSuccess: success
  //       }
  //     });
  //   } 
  //   catch (error) {
  //     const profileUpdateSuccess = error.response ? error.response.data.success : false;
  //     console.log(profileUpdateSuccess, 'profileUpdateSuccessprofileUpdateSuccess')
  //     dispatch({
  //       type: ACTION_TYPES.LOGGEDINPROFILEUPDATE,
  //       payload: { 
  //         isFetching: false,
  //         profileUpdateSuccess: profileUpdateSuccess
  //       }
  //     });
  //   }
  // };

  const fetchAdminUserList = async () => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });

    try {
      const { data } = await apiCaller('post', 'fetch');
      const { admins } = data;
      dispatch({
        type: ACTION_TYPES.ADMINUSERFETCH,
        payload: {
          adminUserList: admins,
          isFetching: false
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACTION_TYPES.ISFETCHING,
        payload: { isFetching: false }
      });
    }
  };

  const approvedFetch = async (payload) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });

    try {
      const { response } = await apiCaller('put', 'grant', payload);
      const { data } = response;
      dispatch({
        type: ACTION_TYPES.APPROVEDROLEFETCH,
        payload: {
          approvedDetails: data,
          isFetching: false
        }
      });
      fetchAdminUserList();
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACTION_TYPES.ISFETCHING,
        payload: { isFetching: false }
      });
    }
  };

  const deleteRoleDetails = async (id) => {
    const { success } = await apiCaller('delete', `/${id}`);
    dispatch({
      type: ACTION_TYPES.DELETEFETCH
    });

    if (success) return Promise.resolve({ success: true });
    fetchAdminUserList();
    return Promise.resolve({ success: false });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        fetchUserList,
        exportUserList,
        updateUser,
        fetchAdminUserList,
        approvedFetch,
        deleteRoleDetails,
        // loggedinProfileUpdate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
