import React, { useState, forwardRef,  useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { months, monthData } from './data';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import {
    Box,
    Card,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Slide,
    CardContent,
    useTheme,
} from '@mui/material';
import CreateEnnopedia from './createEnnopedia';
import Snackbar from '@mui/material/Snackbar';
import { useSnackbar } from 'notistack';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Swal from 'sweetalert2';





let ennopediaInfo = [];
const SlideUp = forwardRef(function SlideUp(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });
const AddEnnopedia = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [ennopediaData, setEnnopediaData] = useState([])
    const [seasonName, setSeasonName] = useState()
    const [monthName, setMonthName] = useState('')
    const [seasonText, setSeasonText] = useState('')
    const [update, setUpdate] = useState({})
    const [isCreatLoading, setIsCreatLoading] = useState(false)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [rows, setRows] = useState(months);
 
    const { search } = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    let token = localStorage.getItem('accessTokenAdmin');
    const handleAddEpisode = (val, index, month) => {
        setMonthName(val.monthName),
            setUpdate({})
        setOpen(!open)
    }

    const blogStatus = (status, message) => {
        enqueueSnackbar(message, {
          variant: status ? 'success' : 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: SlideUp
        });
      };
    const filterCondition = (month) => {
        const matchData = ennopediaData.filter((vl) => vl.month === month)
        return (
            <>{matchData.map((pl) =>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-15px',padding:'10px',height: '40px', alignItems: 'center', paddingTop: '38px'}} onClick={()=> handleOpenDeatilsOnFieldClick(pl)}>
                   <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', paddingBottom: '25px'}}>
                    <div>
                        {pl.categoryName?.name}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        {pl.subCategoryName?.name}
                    </div>
                    </div>
                </div>)}
            </>
        )
    }


    const handleAddSeason = () => {
        if(isCreatLoading || !ennopediaData.length){
            blogStatus(false, 'You need to create episode first!')
        } else {
        setIsCreatLoading(true);
        ennopediaInfo = Array.from(ennopediaData);
        const updatedennopediaInfo = ennopediaInfo.map((obj) => ({
            ...obj,
            alreadySet: false,
        }));
        ennopediaInfo = Array.from(updatedennopediaInfo);

        rows.map((item, index) => {
            let monthName = monthData[index];
            const newMonthName = item.monthName;
            updateMonthBasedOnNewMonthName(newMonthName, monthName);
        })
        let formData = {};
        formData['seasonName'] = seasonName;
        formData['innopediaData'] = ennopediaInfo;
        const serializeData = serialize(
            { ...formData },
            { indices: true }
        );
        axios.post(`${process.env.REACT_APP_URL}/innopedia/create`, serializeData,
            {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            .then((res) => {
                // setRows(months)
                // setEnnopediaData(res.data.data.savedSeason.ennopediaData)
                if(res.data.success){
                    blogStatus(res.data.success, 'Season Added Sucessfully')
                    setIsCreatLoading(false)
                    navigate(`/extended-sidebar/management/innopedia/innopedia-list`)
                }else{
                    blogStatus(false, 'Something went wrong')
                    setIsCreatLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsCreatLoading(false)
                blogStatus(false, 'Something went wrong')
            })
            blogStatus(true, 'Season Created Successfully!')
        }
    }
    const handleGetSeason = async () => {
        await axios.get(`${process.env.REACT_APP_URL}/innopedia/fetchSeason`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                setSeasonName(res.data.season)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleSetEpisodeEditMode = async (id) => {
        await axios.get(`${process.env.REACT_APP_URL}/innopedia/fetchAllEpisode?id=${id}`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                setEnnopediaData(res.data.data.seasonEpisodes.innopediaData)
                setSeasonName(res.data.data.seasonEpisodes.seasonName)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const updateMonthBasedOnNewMonthName = (prevMonth, newMonth) => {
        // this will update prev month with new name
        let newIData = [];
        ennopediaInfo.map((item, index) => {
            let newItem = { ...item };
            if (item.month == prevMonth && newItem.alreadySet == false) {
                newItem.month = newMonth;
                newItem.alreadySet = true;
            }
            newIData.push(newItem);
        });
        ennopediaInfo = Array.from(newIData);
    }

    const handleUpdateSeason = async () => {
        setIsUpdateLoading(true)
        ennopediaInfo = Array.from(ennopediaData);
        const updatedennopediaInfo = ennopediaInfo.map((obj) => ({
            ...obj,
            alreadySet: false,
        }));
        ennopediaInfo = Array.from(updatedennopediaInfo);

        rows.map((item, index) => {
            let monthName = monthData[index];
            const newMonthName = item.monthName;
            updateMonthBasedOnNewMonthName(newMonthName, monthName);      //may, jan    
        })
        let formData = {};
        formData['seasonName'] = seasonName;
        formData['innopediaData'] = ennopediaInfo;
        formData['seasonId'] = search.slice(2);
        const serializeData = serialize(
            { ...formData },
            { indices: true }
        );
        await axios.post(`${process.env.REACT_APP_URL}/innopedia/update`, serializeData, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                if(res.data.success){
                    blogStatus(res.data.success, 'Updated Season Successfully!')
                    setRows(months)
                    setEnnopediaData(res.data.data.updatedSeason.innopediaData)
                    setIsUpdateLoading(false)
                }else{
                    blogStatus(false, 'Season Not Updated');
                    setIsUpdateLoading(false);
                }
            })
            .catch((error) => {
                blogStatus(false, 'Season Not Updated');
                setIsUpdateLoading(false)
                console.error(error)
            })
    }

    const handleEditData = (pl) => {
        setUpdate({ ...pl })
        setOpen(true)
    }

    useEffect(() => {
        if (!search) {
            handleGetSeason()
        }
    }, [])

    useEffect(() => {
        if (search) {
            const match = search.match(/=(.*)/);
            if (match) {
                const result = match[1];
                handleSetEpisodeEditMode(result)
            }
        } else {
            setEnnopediaData([]);
            setRows(months);
            handleGetSeason();

        }
    }, [search])

    const handleShowIcon = (month) => {
        const match = ennopediaData.filter((vl) => vl.month === month)
        return match;
    }

    const handleDragStart = (e, rowIndex) => {
        e.dataTransfer.setData("rowIndex", rowIndex);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e, targetRowIndex) => {
        const sourceRowIndex = e.dataTransfer.getData("rowIndex");
        const draggedRow = rows[sourceRowIndex];

        const newRows = [...rows];
        newRows.splice(sourceRowIndex, 1);
        newRows.splice(targetRowIndex, 0, draggedRow);

        setRows(newRows);
    };
    const handleSaveSeasonName=()=> {
        if(seasonName.length){
            blogStatus(true, 'Season name updated successfully!')
        }else{
            blogStatus(false, 'Enter season name')
        }

    }
   
    const handleOpenDeatilsOnFieldClick=(pl, month)=> {
        handleEditData(pl)
    }
    const handleDelete = (Id) => {
        Swal.fire({
          icon: 'warning',
          title: 'Are you sure to delete this Episode?',
          text: "You won't be able to revert this!",
          confirmButtonColor: theme.palette.success.main,
          confirmButtonText: 'Delete',
          showCancelButton: true,
          cancelButtonColor: theme.palette.error.main,
          cancelButtonText: 'Cancel',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await deleteEpisode(Id);
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire('Deleted!', 'Episode has been successfully deleted.', 'success');
          }
        });
      };

      const deleteEpisode=(Id)=>{
        const newItems = ennopediaData.filter((val) => val.itemIndex !== Id);
        setEnnopediaData(newItems);
      }
    return (
        <>
            {open &&
                <CreateEnnopedia
                    open={open}
                    setOpen={setOpen}
                    seasonName={seasonName}
                    setEnnopediaData={setEnnopediaData}
                    ennopediaData={ennopediaData}
                    monthName={monthName}
                    setMonthName={setMonthName}
                    update={update}
                />}
            <Helmet>
                <title>Add Season</title>
            </Helmet>
            <PageTitleWrapper>
                <Typography variant="h1" component="h1">
                    {t('Add Season')}
                </Typography>
            </PageTitleWrapper>
            <Box sx={{ width: '60%', margin: 'auto', marginBottom: '20px' }}>
                    <Box sx={{ my: 2, display: 'flex', alignItems: 'center', width: '50%' }}>
                        <TextField
                            size="small"
                            InputProps={{
                                style: {
                                    background: "white",
                                    border: 'none',
                                    borderRadius: 0,
                                    borderRadius: '10px',
                                    marginRight: '5px',
                                    height: '43px'
                                },
                                disableUnderline: true
                            }}
                            value={seasonName}
                            onChange={(e) => setSeasonName(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleSaveSeasonName}>
                            Save
                        </Button>
                    </Box>
                <Card>
                    <CardContent>
                        {rows.map((val, index) =>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                <Typography sx={{ width: '10%', color: '#5569FF', fontSize: '14px', fontWeight: 500 }}>
                                    {val.monthName}
                                </Typography>
                                <Card
                                    sx={{ Width: 345, cursor: 'pointer', flexGrow: 1, background: '#EAEAFF', padding: '0px' }}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, index)}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                        <CardContent sx={{ width: '100%', marginBottom: '-9px' ,  minHeight: '58px', padding: '0px' }}>
                                            {filterCondition(val.monthName)}
                                        </CardContent>
                                    </Box>
                                </Card>
                                {handleShowIcon(val.monthName).length ?
                                    <div>
                                        {handleShowIcon(val.monthName).map((pl) =>
                                            <>
                                                <RemoveCircleIcon
                                                    style={{ marginLeft: '2px', cursor: 'pointer',width: '32px',height: '31px', color: 'red' }}
                                                    src="/static/images/logo/editbtnblog.png"
                                                    alt="..."
                                                    // onClick={() => handleEditData(pl)}
                                                    onClick={()=> handleDelete(pl.itemIndex)}
                                                />
                                            </>)}
                                    </div> :
                                    <img
                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                        src="/static/images/logo/addbtnblog.png"
                                        alt="..."
                                        onClick={() => handleAddEpisode(val, index, monthData[index])}
                                    />}
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'sticky', bottom: '0', background:'#ffff'}}>
                {search ?
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button variant="outlined"
                            onClick={handleUpdateSeason}
                            style={{ marginBottom: '20x', minWidth: '647px' }}
                            disabled={isUpdateLoading}
                        >
                            Update Season
                        </Button>
                        {isUpdateLoading &&
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />}
                    </Box> :
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button variant="outlined"
                            onClick={handleAddSeason}
                            sx={{ marginBottom: '20x', minWidth: '647px' }}
                            // disabled={isCreatLoading || !ennopediaData.length}
                        >
                            Add Season
                        </Button>
                        {isCreatLoading &&
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />}
                    </Box>
                }
            </Box>
        </>
    )
}

export default AddEnnopedia 