
export const months = [
    { id: 1, srNo: '1', monthName: 'Jan' },
    { id: 2, srNo: '2', monthName: 'Feb' },
    { id: 3, srNo: '3', monthName: 'Mar' },
    { id: 4, srNo: '4', monthName: 'Apr' },
    { id: 5, srNo: '5', monthName: 'May' },
    { id: 6, srNo: '6', monthName: 'Jun' },
    { id: 7, srNo: '7', monthName: 'Jul' },
    { id: 8, srNo: '8', monthName: 'Aug' },
    { id: 9, srNo: '9', monthName: 'Sept' },
    { id: 10, srNo: '10', monthName: 'Oct' },
    { id: 11, srNo: '11', monthName: 'Nov' },
    { id: 12, srNo: '12', monthName: 'Dec' }
]


export const monthData = [
    'Jan',
    'Feb',
    'Mar',
    'Apr', 
    'May', 
    'Jun', 
    'Jul', 
    'Aug', 
    'Sept',
    'Oct',
    'Nov',
    'Dec'
]