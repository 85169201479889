import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';
import { DatePicker } from '@mui/lab';
import { Typography, Grid, Box, Button, Tooltip, TextField } from '@mui/material';
import useBlog from 'src/hooks/useBlog';
import PartnerWithUsLists from './PartnerWithUsLists';

const PartnerWithUs = () => {
  // const [startDate, setStartDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const [startDate, setStartDate] = useState(new Date('2023-01-01'));
  const [endDate, setEndDate] = useState(new Date());
  const {
    exportUrl,
    isFetchPartnerWithUs,
    fetchPartnerWithUsData,
    partnerWithUsData,
    exportPartnerWithUsData,
    deletePartnerEntry
  } = useBlog();
  const controller = new AbortController();


  const fetchList = async () => {
    try {
      await fetchPartnerWithUsData(startDate, endDate,controller.signal);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchList();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const fetchExportList = async () => {
      try {
        await exportPartnerWithUsData(controller.signal);
      } catch (error) {
        console.log(error);
      }
    };

    fetchExportList();

    return () => controller.abort();
  }, []);

  const handleGetDesiredData = () => {
    fetchPartnerWithUsData(startDate, endDate, controller.signal);
    exportPartnerWithUsData(startDate, endDate, controller.signal);
  };

  return (
    <>
      <Helmet>
        <title>Partner With Us</title>
      </Helmet>
      <PageTitleWrapper>
        <Typography variant="h1">Partner With Us</Typography>
      </PageTitleWrapper>
      <div style={{ marginBottom: '-60px' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          px: 4,
          pb: 4
        }}
      >
        <Tooltip title="Download XLS" arrow>
          <Button
            href={exportUrl}
            variant="contained"
            size="medium"
            download="partner-with-us-users.xlsx"
          >
            XLS
          </Button>
        </Tooltip>
        <div style={{ flex: 4 }} />
        <div style={{ display: 'flex', alignItems: 'center', width: '43%' }}>
          <div style={{ marginRight: '0.1rem', height:"2rem" }}>
            <DatePicker
              label="From Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} size='small' />}
            />
          </div>
          <div style={{ marginRight: '0.1rem', height:"2rem" }}>
            <DatePicker
              label="To Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} size='small' />}
            />
          </div>
          <div style={{ marginLeft: '0.1rem', marginTop: "4px"}}>
          <Button
            onClick={handleGetDesiredData}
            variant="contained"
            size="medium"
          >
            Search
          </Button>
          </div>
                </div>
      </Box>
      </div>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <PartnerWithUsLists
            items={partnerWithUsData.map((item, index) => ({
              sNo: index + 1,
              ...item
            }))}
            isFetching={isFetchPartnerWithUs}
            fetchListTrigger={fetchList}
            deletePartnerEntry={deletePartnerEntry}
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default PartnerWithUs;
