import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications
const Preview = Loader(lazy(() => import('src/content/Preview')));

const previewRoutes = [
  {
    path: ':slug',
    element: <Preview />
  }
];

export default previewRoutes;
