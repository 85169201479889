export function isValidURL(string) {
  // const res = string.match(
  //   /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  // );
  const res = string?.match(
    '\\bhttps?:\\/\\/[\\w\\d\\-@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-\\w\\d@:%_+.~#?&//=]*)',
    'gi'
  );
  return res !== null;
}

export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export function isNumber(num) {
  const res = num.match(/^[0-9]+$/);
  return res;
}
const objectArray = (arr, key = 'id') =>
  arr.reduce((acc, cur) => {
    acc[cur[key]] = cur;
    return acc;
  }, {});

export default objectArray;
