import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialBannerState = {
  bannerList: [],
  banner: null,
  isFetching: false
};

const BannerContext = createContext({
  ...initialBannerState,
  createBanner: () => Promise.resolve(),
  fetchBannerList: () => Promise.resolve(),
  updateBanner: () => Promise.resolve(),
  deleteBanner: () => Promise.resolve(),
  fetchBanner: () => Promise.resolve()
  //   fetchNextBannerList: () => Promise.resolve()
});

const ACTION_TYPES = {
  ISFETCHING: 'ISFETCHING',
  FETCH: 'FETCH',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

const handler = {
  ISFETCHING: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  FETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  CREATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
};

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const BannerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialBannerState);

  const createBanner = async (reqData) => {
    const { data, success } = await apiCaller(
      'post',
      '/banner/create',
      reqData
    );
    const banner = data ? data.data : null;
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: { banner }
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const updateBanner = async (reqData) => {
    const { success } = await apiCaller('put', '/banner', reqData);
    dispatch({
      type: ACTION_TYPES.UPDATE
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const fetchBannerList = async (signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });
    const { data } = await apiCaller(
      'post',
      '/banner/fetch',
      null,
      null,
      signal
    );
    const { allData } = data;
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        bannerList: allData,
        isFetching: false
      }
    });
  };

  const fetchBanner = async (id, signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });

    const { data } = await apiCaller(
      'get',
      `/banner/${id}`,
      null,
      null,
      signal
    );
    const { banner } = data;

    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: { banner, isFetching: false }
    });
  };

  const deleteBanner = async (id) => {
    const { success } = await apiCaller('delete', `/banner/${id}`);
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  return (
    <BannerContext.Provider
      value={{
        ...state,
        createBanner,
        fetchBannerList,
        updateBanner,
        deleteBanner,
        fetchBanner
        // fetchNextBannerList
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};

export default BannerContext;
