import React, { useEffect,useState } from 'react';

import { Helmet } from 'react-helmet-async';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';

import { Typography, Grid, Box, Button, Tooltip } from '@mui/material';

import Campaigns from './Campaigns';
import {campaignData}  from '../../../constant/campaigns';
import axios from 'axios';

const FestivalCampaign = () => {
  let token = localStorage.getItem("accessTokenAdmin");
  const [loader, setLoader] = useState();
 
  
  
  const exportfetchCampaign = async (payload) => {
    const dataPayload = { ...payload, "isAdmin": true };
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/fetchCampaign`, dataPayload, {
        headers: {
          'Authorization': `token ${token}`,
        },
        responseType: 'arraybuffer', 
      });
      const arrayBuffer = response.data;
      const blob = new Blob([arrayBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobURL;
      link.download = `${dataPayload.campaignName} ${dataPayload.year}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  


  return (
    <>
      <Helmet>
        <title>Campaigns</title>
      </Helmet>
      <PageTitleWrapper>
        <Typography variant="h1">Campaigns</Typography>
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Campaigns
            items={campaignData}
            exportfetchCampaign={exportfetchCampaign}
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default FestivalCampaign;
