import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Tooltip,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LogsData } from './constant';
import axios from 'axios';

const LogList = () => {
  const { t } = useTranslation();
  let token = localStorage.getItem("accessTokenAdmin");

  const handldeExportedFunction= async(value)=> {
    const data= {
        moduleName: value.moduleName
    }
    try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/logger/export`, data, {
          headers: {
            'Authorization': `token ${token}`,
          },
          responseType: 'arraybuffer', 
        });
        const arrayBuffer = response.data;
        const blob = new Blob([arrayBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = `${value.displayName}_log_details.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
  }
  return (
        <>
            <Card>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('S.No')}</TableCell>
                      <TableCell align="center">{t('Module Name')}</TableCell>
                      <TableCell align="center">{t('Download')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {LogsData.map((val, idx) => {
                      return (
                        <TableRow key={idx}>
                          <TableCell align="center">{val.Sno}</TableCell>
                          <TableCell align="center">{val.displayName}</TableCell>

                          <TableCell align="center">
                              <Button
                                onClick={() => handldeExportedFunction(val)}
                                variant="contained"
                                size="small"
                                sx={{cursor: "pointer"}}
                              >
                               Download XLS
                              </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
        </>
  );
};

LogList.propTypes = {
  items: PropTypes.array.isRequired,
  exportfetchCampaign: PropTypes.func.isRequired
};

LogList.defaultProps = {
  items: [],
};

export default React.memo(LogList);
