import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialMemberState = {
    memberList: [],
    member: null,
    isFetching: false
}

const MemberContext = createContext({
    ...initialMemberState,
    createMember: () => Promise.resolve(),
    fetchMemberList: () => Promise.resolve()
});

const ACTION_TYPES = {
    ISFETCHING: 'ISFETCHING',
    FETCH: 'FETCH',
    CREATE: 'CREATE',
};


const handler = {
    ISFETCHING: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    FETCH: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    CREATE: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    }
}

const reducer = (state, { type, payload }) => {
    return handler[type] ? handler[type](state, payload) : state;
}

export const MemberContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialMemberState);

    const createMember = async reqData => {
        const { data, success } = await apiCaller('post', '/team/create', reqData);
        const admin = data ? data.newAdmin : null;
        dispatch({
            type: ACTION_TYPES.CREATE,
            payload: {
                member: admin
            }
        });
        if (success) return Promise.resolve({ success: true });
        return Promise.resolve({ success: false });
    }

    const fetchMemberList = async signal => {
        dispatch({
            type: ACTION_TYPES.ISFETCHING,
            payload: { isFetching: true }
        })
        const { data } = await apiCaller('post', '/team/fetch', null, null, signal);
        const { allData } = data;
        dispatch({
            type: ACTION_TYPES.FETCH,
            payload: {
                memberList: allData,
                isFetching: false
            }
        });
    }

    return (
        <MemberContext.Provider
            value={{
                ...state,
                createMember,
                fetchMemberList
            }}
        >
            {children}
        </MemberContext.Provider>
    )

}

export default MemberContext;