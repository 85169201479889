export const LogsData = [
    { 
       Sno: 1, 
       moduleName: "Blog", 
       action: "Download",
       displayName: 'Post'
    },
    { 
        Sno: 2, 
        moduleName: "NewBlog", 
        action: "Download",
        displayName: 'Blog'
    },
    { 
        Sno: 3, 
        moduleName: "Admin", 
        action: "Download",
        displayName: 'Admin'
    },
    { 
        Sno: 4, 
        moduleName: "Innopedia", 
        action: "Download",
        displayName: 'Innopedia',
    },
    ]