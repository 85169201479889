import React from 'react';
import useCategory from 'src/hooks/useCategory';
import useSubCategory from 'src/hooks/useSubCategory';
import BarChart from './components/BarChart';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

const yearlyLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const UserSession = () => {
  const { categoryList, fetchCategoryList } = useCategory();
  const { subCategoryList, fetchSubCategoryList } = useSubCategory();
  let token = localStorage.getItem('accessTokenAdmin');
  const controller = new AbortController();
  // const [data, setData] = useState([]);

  const [yearlyCategorySeries, setYearlyCategorySeries] = useState([]);
  const [yearlySubcategorySeries, setYearlySubcategorySeries] = useState([]);

  const [monthlyCategorySeries, setMonthlyCategorySeries] = useState([]);
  const [monthlySubcategorySeries, setMonthlySubcategorySeries] = useState([]);

  const [showCategoryYearly, toggleCategoryYearly] = useState(true);
  const [showSubcategoryYearly, toggleSubcategoryYearly] = useState(true);

  const [category, setCategory] = useState('');
  //   const [selectedCategoryTest, setCategoryTest] = useState('');
  const [subCategory, setSubCategory] = useState('');

  const [yearlyLabel, setYearlyLabel] = useState(yearlyLabels);
  const [monthlyCategoryLabel, setMonthlyCategoryLabel] = useState([]);
  const [monthlySubcategoryLabel, setMonthlySubcategoryLabel] = useState([]);

  const [cgMonth, setcgMonth] = useState('');
  const [scgMonth, setscgMonth] = useState('');

  const createYearlySeriesAndRequiredData = (apiData, setterFn) => {
    let ourSeries = [];
    let usersObject = {
      name: 'Users',
      data: []
    };

    let sessionObject = {
      name: 'Total Visit',
      data: []
    };

    //think of something in such a way that , no need to create two objects
    apiData.data.map((item, index) => {
      usersObject.data.push(item?.users || 0);
      sessionObject.data.push(item?.sessions || 0);
    });

    //change it to spread operator
    ourSeries.push(usersObject);
    ourSeries.push(sessionObject);
    setterFn(Array.from(ourSeries));
  };

  const createMonthlySeriesAndRequiredData = (
    apiData,
    setterFn,
    monthlySetterFn
  ) => {
    let ourSeries = [];
    let usersObject = {
      name: 'Users',
      data: []
    };

    let sessionObject = {
      name: 'Sessions',
      data: []
    };

    //think of something in such a way that , no need to create two objects
    const datesInSelectedMonth = Object.keys(apiData.data);
    monthlySetterFn(Array.from(datesInSelectedMonth));
    datesInSelectedMonth.map((date, index) => {
      usersObject.data.push(apiData.data[date]?.users || 0);
      sessionObject.data.push(apiData.data[date]?.sessions || 0);
    });

    //change it to spread operator
    ourSeries.push(usersObject);
    ourSeries.push(sessionObject);
    setterFn(Array.from(ourSeries));
  };

  const fetchYearlyUserSessionData = async (type) => {
    if (category == undefined || category == '') {
      return;
    }

    if (type == 'category') {
      toggleCategoryYearly(true);
    }

    if (type == 'subCategory') {
      if (subCategory == undefined || subCategory == '') {
        return;
      }
      toggleSubcategoryYearly(true);
    }
    const urlParam =
      type == 'category'
        ? `categoryId=${category}`
        : `subCategoryId=${subCategory}`;
    const setterFn =
      type == 'category' ? setYearlyCategorySeries : setYearlySubcategorySeries;
    axios
      .get(
        `${process.env.REACT_APP_URL}/analytics/userSessionYearWise?${urlParam}&year=2023`,
        {
          headers: {
            Authorization: `token ${token}`
          }
        }
      )
      .then((res) => {
        console.log('success', res);
        createYearlySeriesAndRequiredData(res.data, setterFn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMonthlyUserSessionData = async (type) => {
    const urlParam =
      type == 'category'
        ? `categoryId=${category}`
        : `subCategoryId=${subCategory}`;

    const setterSeriesFn =
      type == 'category'
        ? setMonthlyCategorySeries
        : setMonthlySubcategorySeries;

    const setterLabelFn =
      type == 'category' ? setMonthlyCategoryLabel : setMonthlySubcategoryLabel;

    const month = type == 'category' ? cgMonth : scgMonth;

    axios
      .get(
        `${process.env.REACT_APP_URL}/analytics/userSessionMonthWise?${urlParam}&year=2023&month=${month}`,
        {
          headers: {
            Authorization: `token ${token}`
          }
        }
      )
      .then((res) => {
        createMonthlySeriesAndRequiredData(
          res.data,
          setterSeriesFn,
          setterLabelFn
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }

  const yearlyCallback = ({ config }) => {
    toggleCategoryYearly((prev) => !prev);
    setcgMonth(minTwoDigits(config?.dataPointIndex + 1));
  };

  const monthlyCallback = (e) => {
    toggleCategoryYearly((prev) => !prev);
  };

  const yearlySubcategoryCallback = ({ config }) => {
    toggleSubcategoryYearly((prev) => !prev);
    setscgMonth(minTwoDigits(config?.dataPointIndex + 1));
  };

  const monthlySubcategoryCallback = (e) => {
    toggleSubcategoryYearly((prev) => !prev);
  };

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchCategoryList(controller.signal);
      } catch (error) {
        console.log(error);
      }
    };
    fetchList();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchSubCategoryList(category, controller.signal);
      } catch (error) {
        console.log(error);
      }
    };
    fetchList();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    fetchYearlyUserSessionData('category');
  }, [category]);

  useEffect(() => {
    fetchYearlyUserSessionData('subCategory');
  }, [subCategory]);

  useEffect(() => {
    fetchMonthlyUserSessionData('category');
  }, [cgMonth]);

  useEffect(() => {
    fetchMonthlyUserSessionData('subCategory');
  }, [scgMonth]);

  console.log('states', {
    category,
    subCategory,
    yearlyCategorySeries,
    yearlySubcategorySeries
  });
  return (
    <>
      <Box>
        <FormControl margin="normal" sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel id="select-label">Category</InputLabel>
          <Select
            labelId="select-label"
            id="categoryId"
            value={category}
            label="categoryId"
            onChange={(event) => setCategory(event.target.value)}
          >
            {categoryList.map(({ name, _id }) => (
              <MenuItem key={_id} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {showCategoryYearly ? (
        <BarChart
          series={yearlyCategorySeries}
          categories={yearlyLabel}
          onBarClick={yearlyCallback}
        />
      ) : (
        <BarChart
          series={monthlyCategorySeries}
          categories={monthlyCategoryLabel}
          onBarClick={monthlyCallback}
        />
      )}
      <Box>
        <FormControl margin="normal" sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel id="select-label">Sub Category</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            name="subCategoryId"
            value={subCategory}
            label="Sub Category"
            onChange={(event) => {
              setSubCategory(event.target.value);
            }}
          >
            {subCategoryList
              .filter((item) => item.categoryId === category)
              .map(({ name, _id }) => (
                <MenuItem key={_id} value={_id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {showSubcategoryYearly ? (
        <BarChart
          series={yearlySubcategorySeries}
          categories={yearlyLabel}
          onBarClick={yearlySubcategoryCallback}
        />
      ) : (
        <BarChart
          series={monthlySubcategorySeries}
          categories={monthlySubcategoryLabel}
          onBarClick={monthlySubcategoryCallback}
        />
      )}
    </>
  );
};

export default UserSession;
