import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import CategoryChartGraph from './categoryChartGraph';
import axios from 'axios';
import CircularIndeterminate from '../loaderComponent';

const PostChartComponent = ({ selectYear, barColor }) => {
  let token = localStorage.getItem("accessTokenAdmin");
  const[postData, setPostData]= useState([])
  const[seriesData, setSeriesData]= useState([])
  const[monthlyData, setMonthlyData]= useState({})
  const[loader, setLoader]= useState();

  const prepareSeriesAndRequiredData = (categoriesData) => {
      const seriesInfo = [];
      categoriesData.map((item, index)=>{
        const currentCategorySeries = {
          name: '',
          data: []
        }
        currentCategorySeries.name = item.categoryName;
        const keys = Object.keys(item.months);
        keys.map((currMonth,index)=> {
          currentCategorySeries.data.push(item.months[currMonth].count) 
        })
        seriesInfo.push(currentCategorySeries); 
      })
      setSeriesData([...seriesInfo]);
  }

  const handleFetchPostData = async () => {
    setLoader(true)
    axios.get(`${process.env.REACT_APP_URL}/analytics/categoryDashboardData?year=${selectYear}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
      .then((res) => {
        setLoader(false)
        setPostData([...res.data.data.category])
        prepareSeriesAndRequiredData(res.data.data.category);

      })
      .catch((error) => {
        setLoader(false)
        console.error(error)
      })
  }
  const handlePostData =()=> {
    postData.map((val)=> {
      setMonthlyData(val.months)
    })
  }
  const options = {
    chart: {
      toolbar: {
        show: false
        },
      height: 350,
      type: 'bar',
    
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: 'solid',
      opacity: [0.35, 1],
    },
    labels: Object.keys(monthlyData) || [],
    markers: {
      size: 0
    },
    yaxis: {
      title: {
        text: 'Total Posts per Month',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) +"  Posts";
          }
          return y;
        }
      }
    }
  }
  useEffect(() => {
    handleFetchPostData()
  }, [selectYear])

  useEffect(()=>{
    if(postData.length){
       handlePostData()
    }
  },[postData])
  
  return (
    <>
      <div id="chart" style={{width: '100%'}}>
        {loader? <CircularIndeterminate /> :
        <ReactApexChart options={options} series={seriesData} type="line" height={350} />}
      </div>
      <CategoryChartGraph barColor={barColor} />
    </>
  );
};

export default PostChartComponent;