import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import CircularIndeterminate from './loaderComponent';

const ChartComponent = ({ yearlyData, monthlyData, loader, graphTitle, barColor }) => {
    const[dataShown, setDataShown]= useState();
    const handleYearlyData=(yearlyData)=> {
      setDataShown([...Object.keys(yearlyData).map((val)=> yearlyData[val].count)])
    };
    const options = {
        chart: {
            toolbar: {
                show: false
              },
          },
        xaxis: {
            categories: Object.keys(yearlyData),
        },
        yaxis: {
            title: {
              text: `Total ${graphTitle} Per Month`,
            },
          },
        fill: {
            colors: [barColor]
          }
    };

    const series = [
        {
            name: 'visit',
            data: dataShown
        }
    ];
    useEffect(()=>{
     handleYearlyData(yearlyData)
    },[yearlyData])
  
    return (
        <div className="chart" style={{width: '100%'}}>
            {loader? <CircularIndeterminate /> :
            <ReactApexChart options={options} series={series} type="bar" height={350} />}
        </div>
    );
};

export default ChartComponent;