import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';

export default function Details({ open, handleClickClose, matches, post }) {
  console.log('Post', post);
  return (
    <Dialog
      open={open}
      onClose={handleClickClose}
      fullScreen={matches}
      maxWidth={matches ? 'lg' : false}
    >
      <DialogTitle>Details</DialogTitle>
      <Divider />
      <DialogContent>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.secondary"
              gutterBottom
            >
              Name : {post.fullname}
            </Typography>

            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.secondary"
              gutterBottom
            >
              Email : {post.email}
            </Typography>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.secondary"
              gutterBottom
            >
              Mobile : {post.number}
            </Typography>

            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.secondary"
              gutterBottom
            >
              Website : {post.websiteURL}
            </Typography>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.secondary"
              gutterBottom
            >
              Company : {post.company}
            </Typography>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.secondary"
              gutterBottom
            >
              Industry : {post.industry}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Expect : {post.expect.toString()}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: 1.5, width: 300 }}
              color="text.secondary"
            >
              Message : {post.message}
              <br />
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={handleClickClose}>
              <Typography variant="body2">
                Close
                <br />
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
