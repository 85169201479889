import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import './AddEnnopedia/ennopediaStyle.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {
    Box,
    Card,
    Typography,
    CardContent
} from '@mui/material';


const EnnopediaList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let token = localStorage.getItem('accessTokenAdmin');
    const [allSeason, setAllseason] = useState([]);
    const handleReturnAllEpisodeOfCurrentSeason = (vl) => {
        navigate(`/extended-sidebar/management/innopedia/add-innopedia/?=${vl}`)
    }

    const handleGetAllSeason = async () => {
        axios.get(`${process.env.REACT_APP_URL}/innopedia/fetchAllSeason`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                setAllseason(res.data.data.data)
            })
            .catch((error) => {
                console.error(error)
            })

    }
    useEffect(() => {
        handleGetAllSeason()
    }, [])

    return (
        <>
            <Helmet>
                <title>Season List</title>
            </Helmet>
            <PageTitleWrapper>
                <Typography variant="h1" component="h1">
                    {t('Season List')}
                </Typography>
            </PageTitleWrapper>
            <Box sx={{ width: '50%', margin: 'auto' }}>
                <Card >
                    <CardContent>
                        {allSeason.length? allSeason?.map((val) =>
                            <Card sx={{ Width: 345, marginBottom: '10px', cursor: 'pointer' }}>
                                <CardContent sx={{ border: '1px solid lightgray', borderRadius: '11px', paddingBottom: '11px' }} onClick={() => handleReturnAllEpisodeOfCurrentSeason(val._id)}>
                                    <Box style={{ marginBottom: '-8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography gutterBottom variant="h5" component="div" >
                                            {val.seasonName}
                                        </Typography>
                                        <ArrowForwardIosIcon />
                                    </Box>
                                </CardContent>
                            </Card>):
                                <Stack spacing={1}>
                                <Skeleton variant="rectangular" width={'100%'} height={40} />
                                <Skeleton variant="rounded" width={'100%'} height={40} />
                              </Stack>
                            }
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default EnnopediaList;