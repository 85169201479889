import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialVideoState = {
    videoList: [],
    video: null,
    isFetching: false,
}

const VideoContext = createContext({
    ...initialVideoState,
    createVideo: () => Promise.resolve(),
    fetchVideoList: () => Promise.resolve()
});

const ACTION_TYPES = {
    ISFETCHING: 'ISFETCHING',
    FETCH: 'FETCH',
    CREATE: 'CREATE',
};


const handler = {
    ISFETCHING: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    FETCH: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    CREATE: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    }
}

const reducer = (state, { type, payload }) => {
    return handler[type] ? handler[type](state, payload) : state;
}

export const VideoContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialVideoState);

    const createVideo = async reqData => {
        const { data, success } = await apiCaller('post', '/video/create', reqData);
        dispatch({
            type: ACTION_TYPES.CREATE,
            payload: {
                video: data || null
            }
        });
        if (success) return Promise.resolve({ success: true });
        return Promise.resolve({ success: false });
    }

    const fetchVideoList = async signal => {
        dispatch({
            type: ACTION_TYPES.ISFETCHING,
            payload: { isFetching: true }
        })
        const { data } = await apiCaller('post', '/video/fetch', null, null, signal);
        const { allData } = data;
        dispatch({
            type: ACTION_TYPES.FETCH,
            payload: {
                videoList: allData,
                isFetching: false
            }
        });
    }

    return (
        <VideoContext.Provider
            value={{
                ...state,
                createVideo,
                fetchVideoList
            }}
        >
            {children}
        </VideoContext.Provider>
    )

}

export default VideoContext;