import React, { useState, useEffect, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Slide
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import useAuth from 'src/hooks/useAuth';
import useUser from 'src/hooks/useUser';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const SlideUp = forwardRef(function SlideUp(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const EditProfile = ({ editOpen, user, setEditOpen }) => {
  const [name, setName] = useState('');
  const [userGmail, setUserGmail] = useState('');
  const [updatedPassword, setUpdatedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword]= useState(false)
  const [confirmedPassword, setConfirmedPassword]= useState('')
  const { loggedinProfileUpdate, profileUpdateSuccess, isFetching } = useUser();
  let token = localStorage.getItem('accessTokenAdmin');
  const[error, setError]=useState({
    updatedPassword: '',
     confirmedPassword: '',
     name: '',
  })

  const { enqueueSnackbar } = useSnackbar();

  const blogStatus = (status, message) => {
    enqueueSnackbar(message, {
      variant: status ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: SlideUp
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmedPasswordVisibility=()=> {
    setShowConfirmedPassword((prev)=> !prev)
  }

  const handleClose = () => {
    setEditOpen(false);
  };

  useEffect(() => {
    setName(user.name);
    setUserGmail(user.email);
  }, []);

  const handleUpdate = async () => {
    const error={};
    if (!name) {
      error.name = 'name is required';
    }
    if (!updatedPassword) {
      error.updatedPassword = 'Password is required';
    }
    if (updatedPassword !== confirmedPassword) {
      error.confirmedPassword = 'Passwords do not match';
    }
    setError(error);
    if (Object.keys(error).length === 0) {
    try {
      const payload = {
        name: name,
        password: updatedPassword,
        email: userGmail,
        role: user.role,
        userId: user._id
      };
      await axios
        .put(`${process.env.REACT_APP_URL}/update`, payload, {
          headers: {
            Authorization: `token ${token}`
          }
        })
        .then((res) => {
          if (res) {
            blogStatus(true, 'Profile Updated Successfully');
          } else {
            blogStatus(false, 'Something Went Wrong!');
          }
          setEditOpen(false);
        });
    } catch (error) {
      console.log(error, 'error');
      blogStatus(false, 'Something Went Wrong!');
      setEditOpen(false);
    }
  }
  };

  return (
    <React.Fragment>
      <Dialog
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '4px 52px'
          }}
        >
          Update Profile Information
        </h2>
        <DialogContent style={{ margin: '-15px 10px' }}>
          <Box sx={{ marginBottom: '30px' }}>
            <TextField
              size="small"
              type="name"
              label="User Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              error={Boolean(error.name)}
              helperText={error.name}
            />
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <TextField
              disabled
              size="small"
              type="email"
              label="Enter email"
              value={userGmail}
              onChange={(e) => setUserGmail(e.target.value)}
              fullWidth
            />
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <TextField
              size="small"
              type={showPassword ? 'text' : 'password'}
              label="Updated Password"
              value={updatedPassword}
              fullWidth
              onChange={(e) => setUpdatedPassword(e.target.value)}
              error={Boolean(error.updatedPassword)}
              helperText={error.updatedPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>

          <Box>
            <TextField
              size="small"
              type={showConfirmedPassword ? 'text' : 'password'}
              label="Confirmed Password"
              value={confirmedPassword}
              fullWidth
              onChange={(e) => setConfirmedPassword(e.target.value)}
              error={Boolean(error.confirmedPassword)}
              helperText={error.confirmedPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConfirmedPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmedPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default EditProfile;
