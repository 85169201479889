// import React from 'react';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import CircularIndeterminate from './loaderComponent';

const MonthlyChartComponent = ({ monthlyData, selectMonth, loader, graphTitle, barColor }) => {
    const [dataShown, setDataShown] = useState([]);
    const [myMonthDate, setMyMonthDate] = useState([])
    const handleMonthlyData = (monthData) => {
        setMyMonthDate(Object.keys(monthData))
        const mData = Object.keys(monthData).map((val) => monthData[val]);
        setDataShown([...mData])
    };
    const options = {
        chart: {
            toolbar: {
                show: false
              },
            type: 'line',
          },
        xaxis: {
            categories: myMonthDate,
        },
        yaxis: {
            title: {
              text: `Total ${graphTitle} Per Day`,
            },
          },
    };

    const series = [
        {
            name: 'Visit',
            data: dataShown,
            color: barColor,
        },
    ];
    useEffect(() => {
        if (Object.keys(monthlyData)?.length > 0) {
            handleMonthlyData(monthlyData?.days)
        }
    }, [monthlyData])

    return (
        <div className="chart" style={{width: '100%'}}>
            {loader? <CircularIndeterminate /> :
            <ReactApexChart options={options} series={series} type='line' height={350} />}
        </div>
    );
};

export default MonthlyChartComponent;