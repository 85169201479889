import { createContext, useReducer, useEffect } from 'react';
import { apiCaller, instanceOfAxios } from 'src/utils/apiCaller';

const initialAppAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null,
  createUser: null,
};

const setSession = (accessTokenAdmin) => {
  if (accessTokenAdmin) {
    localStorage.setItem('accessTokenAdmin', accessTokenAdmin);
    instanceOfAxios.defaults.headers.common.Authorization = `Bearer ${accessTokenAdmin}`;
  } else {
    localStorage.removeItem('accessTokenAdmin');
    delete instanceOfAxios.defaults.headers.common.Authorization;
  }
};

const setUserSesssion = (user) => {
  if (user) {
    if (user instanceof Object) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.setItem('user', user);
    }
  } else {
    localStorage.removeItem('user');
  }
};

const ACTION_TYPES = {
  INITIALIZE: 'INITIALIZE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SIGNUP: 'SIGNUP',
};

const handler = {
  INITIALIZE: (state, payload) => {
    return {
      ...state,
      ...payload,
      isInitialized: true
    };
  },

  SIGNUP: (state, { newAdmin, success })=> {
   return {
    ...state,
    createUser: newAdmin,
    isAuthenticated: success,
    // token
   }
  },

  LOGIN: (state, { token, admin, success }) => {
    return {
      ...state,
      user: admin,
      isAuthenticated: success,
      token
    };
  },
  LOGOUT: (state) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      token: null
    };
  }
};

const AppAuthContext = createContext({
  ...initialAppAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
});

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const AppAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppAuthState);

  useEffect(() => {
    const initializeApp = () => {
      const accessTokenAdmin = localStorage.getItem('accessTokenAdmin');
      const user = localStorage.getItem('user');

      if (accessTokenAdmin && user) {
        setSession(accessTokenAdmin);
        setUserSesssion(user);
        dispatch({
          type: ACTION_TYPES.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: JSON.parse(user),
            token: accessTokenAdmin
          }
        });
      } else {
        dispatch({
          type: ACTION_TYPES.INITIALIZE,
          payload: {
            isAuthenticated: false
          }
        });
      }
    };

    initializeApp();
  }, []);

  const login = async (userData) => {
    const { data, success } = await apiCaller('post', 'login', userData);

    const { token, admin } = data;
    setSession(token);
    setUserSesssion(admin);
    dispatch({
      type: ACTION_TYPES.LOGIN,
      payload: { token, admin, success }
    });
  };

  const signUp = async (userDetails) => {
    const { data, success } = await apiCaller('post', 'create', userDetails);
    const { token, newAdmin } = data;
    // setSession(token);
    // setUserSesssion(newAdmin);
    dispatch({
      type: ACTION_TYPES.SIGNUP,
      payload: { newAdmin, success, token }
    });
  };

  const logout = async () => {
    setSession(null);
    setUserSesssion(null);
    dispatch({
      type: ACTION_TYPES.LOGOUT,
      payload: {
        isAuthenticated: false,
        user: null,
        token: null
      }
    });
  };

  return (
    <AppAuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        signUp,
      }}
    >
      {children}
    </AppAuthContext.Provider>
  );
};

export default AppAuthContext;
