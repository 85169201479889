import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialSubscriberState = {
    subscriberList: [],
    exportUrl: null,
    isFetching: false
}

const SubscriberContext = createContext({
    ...initialSubscriberState,
    exportSubscriberList: () => Promise.resolve(),
    fetchSubscriberList: () => Promise.resolve()
});

const ACTION_TYPES = {
    ISFETCHING: 'ISFETCHING',
    FETCH: 'FETCH',
    EXPORT: 'EXPORT',
};


const handler = {
    ISFETCHING: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    FETCH: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    EXPORT: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    }
}

const reducer = (state, { type, payload }) => {
    return handler[type] ? handler[type](state, payload) : state;
}

export const SubscriberContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialSubscriberState);

    const exportSubscriberList = async (startDate, endDate, signal) => {
        const requestData = { startDate, endDate }; 
        const data = await apiCaller('post', '/newsletter/export', requestData, null, signal, 'arraybuffer');
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const blobURL = URL.createObjectURL(blob);
        dispatch({
            type: ACTION_TYPES.EXPORT,
            payload: {
                exportUrl: blobURL
            }
        });
    }

    const fetchSubscriberList = async (startDate, endDate,signal) => {
        const requestData = { startDate, endDate };
        dispatch({
            type: ACTION_TYPES.ISFETCHING,
            payload: { isFetching: true }
        });
        const { data } = await apiCaller('post', '/newsletter/fetch', requestData, null, signal);
        const { allData } = data;
        dispatch({
            type: ACTION_TYPES.FETCH,
            payload: {
                subscriberList: allData,
                isFetching: false
            }
        });
    }

    return (
        <SubscriberContext.Provider
            value={{
                ...state,
                fetchSubscriberList,
                exportSubscriberList
            }}
        >
            {children}
        </SubscriberContext.Provider>
    )

}

export default SubscriberContext;