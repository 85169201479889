import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import useAuth from './hooks/useAuth';
import { CategoryContextProvider } from './contexts/CategoryContext';
import { SubCategoryContextProvider } from './contexts/SubCategoryContext';
import { VideoContextProvider } from './contexts/VideoContext';
import { RoleContextProvider } from './contexts/RoleContext';
import { UserContextProvider } from './contexts/UserContext';
import { SubCategoryContextFieldProvider } from './contexts/SubCategoryFieldsContext';
import { BlogContextProvider } from './contexts/BlogContext';
import { BannerContextProvider } from './contexts/BannerContext';
import { SubscriberContextProvider } from './contexts/SubscriberContext';
import { MemberContextProvider } from './contexts/MemberContext';
import { CategoryFieldContextProvider } from './contexts/CategoryFieldContext';
import { NewBlogContextProvider } from './contexts/NewBlogContext';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? (
            <CategoryContextProvider>
              <SubCategoryContextProvider>
                <VideoContextProvider>
                  <RoleContextProvider>
                    <UserContextProvider>
                      <SubscriberContextProvider>
                        <SubCategoryContextFieldProvider>
                          <BannerContextProvider>
                            <BlogContextProvider>
                              <NewBlogContextProvider>
                                <MemberContextProvider>
                                  <CategoryFieldContextProvider>
                                    {content}
                                  </CategoryFieldContextProvider>
                                </MemberContextProvider>
                              </NewBlogContextProvider>
                            </BlogContextProvider>
                          </BannerContextProvider>
                        </SubCategoryContextFieldProvider>
                      </SubscriberContextProvider>
                    </UserContextProvider>
                  </RoleContextProvider>
                </VideoContextProvider>
              </SubCategoryContextProvider>
            </CategoryContextProvider>
          ) : (
            <AppInit />
          )}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
