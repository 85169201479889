import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialCategoryState = {
  categoryList: [],
  courseList: [],
  course: null,
  category: null,
  isFetching: false
};

const CategoryContext = createContext({
  ...initialCategoryState,
  createCategory: () => Promise.resolve(),
  fetchCategoryList: () => Promise.resolve(),
  fetchCourseList: () => Promise.resolve(),
  createCourse: () => Promise.resolve(),
  updateCategory: () => Promise.resolve(),
  deleteCategory: () => Promise.resolve(),
  fetchCategory: () => Promise.resolve()
});

const ACTION_TYPES = {
  ISFETCHING: 'ISFETCHING',
  FETCH: 'FETCH',
  CREATE: 'CREATE'
};

const handler = {
  ISFETCHING: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  FETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  CREATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
};

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const CategoryContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCategoryState);

  const createCategory = async (reqData) => {
    const { data, success } = await apiCaller(
      'post',
      '/category/create',
      reqData
    );
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        category: data
      }
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const createCourse = async (reqData) => {
    const { data, success } = await apiCaller(
      'post',
      '/course/create',
      reqData
    );
    console.log(data, 'context api');
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        course: data
      }
    });
    console.log(data, 'context');
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const fetchCategoryList = async (signal, isBlogCategory) => {
    let showsAllCategory;
    if(isBlogCategory){
      showsAllCategory= true;
    }else{
      showsAllCategory= false;
    }
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });
    const { data } = await apiCaller(
      'post',
      '/category/fetch',
      null,
      { allCategories: showsAllCategory },
      signal,
    );
    const { allData } = data;
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        categoryList: allData,
        isFetching: false
      }
    });
  };
  
  const fetchCourseList = async (signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });
    const { data } = await apiCaller(
      'post',
      '/course/fetch',
      null,
      null,
      signal
    );
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        courseList: data ? data.allData : '',
        isFetching: false
      }
    });
  };

  const fetchCategory = async (id, signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });

    const { data } = await apiCaller(
      'get',
      `/category/${id}`,
      null,
      null,
      signal
    );
    const { category } = data;

    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: { category, isFetching: false }
    });
  };

  const deleteCategory = async (id) => {
    const { success } = await apiCaller('delete', `/category/${id}`);
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const updateCategory = async (reqData) => {
    const { success } = await apiCaller('put', '/category', reqData);
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        isFetching: false
      }
    });
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  return (
    <CategoryContext.Provider
      value={{
        ...state,
        createCategory,
        fetchCategoryList,
        fetchCourseList,
        createCourse,
        deleteCategory,
        fetchCategory,
        updateCategory
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryContext;
