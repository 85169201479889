import axios from 'axios';
import { appConfig } from 'src/config';

const instanceOfAxios = axios.create({
  baseURL: appConfig.baseURL
});

instanceOfAxios.interceptors.request.use(
  (config) => {
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

instanceOfAxios.interceptors.response.use(
  (response) => {
    if (response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      return Promise.reject(error.response);
    }
    if (error.request) {
      return Promise.reject(error.request);
    }
    return Promise.reject(error.message);
  }
);

const apiCaller = async (
  method = 'get',
  endpoint,
  body,
  params,
  signal,
  resType
) => {
  return instanceOfAxios({
    method,
    url: endpoint,
    data: body,
    params,
    signal,
    responseType: resType
  });
};

const allApiCaller = async (request = []) => {
  return axios.all(request);
};

export { instanceOfAxios, apiCaller, allApiCaller };
