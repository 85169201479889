import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialSubCategoryFieldsState = {
  subCategoryFieldList: [],
  subCategoryField: null
};

const SubCategoryContextField = createContext({
  ...initialSubCategoryFieldsState,
  createSubCategoryField: () => Promise.resolve(),
  fetchSubCategoryFieldList: () => Promise.resolve(),
  updateSubCategoryField: () => Promise.resolve(),
  deleteSubCategoryField: () => Promise.resolve()
});

const ACTION_TYPES = {
  FETCH: 'FETCH',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
};

const handler = {
  FETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  CREATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  UPDATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  DELETE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
};

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const SubCategoryContextFieldProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialSubCategoryFieldsState);

  const createSubCategoryField = async (reqData) => {
    const { success } = await apiCaller('post', '/sub-category-field/create', {
      fields: [reqData]
    });
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        subCategoryField: reqData
      }
    });
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const fetchSubCategoryFieldList = async (reqData, signal) => {
    try {
      const { data } = await apiCaller(
        'post',
        '/sub-category-field/fetch',
        reqData,
        null,
        signal
      );
      const { allData } = data;
      dispatch({
        type: ACTION_TYPES.FETCH,
        payload: {
          subCategoryFieldList: allData
        }
      });
    } catch (error) {
      console.log('Sub category field error', error);
    }
  };

  const updateSubCategoryField = async (reqData) => {
    const { success } = await apiCaller('put', '/sub-category-field', reqData);
    dispatch({
      type: ACTION_TYPES.UPDATE
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const deleteSubCategoryField = async (reqData, id) => {
    const { success } = await apiCaller(
      'delete',
      `/sub-category-field/${id}`,
      reqData
    );
    dispatch({
      type: ACTION_TYPES.DELETE
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  return (
    <SubCategoryContextField.Provider
      value={{
        ...state,
        createSubCategoryField,
        fetchSubCategoryFieldList,
        updateSubCategoryField,
        deleteSubCategoryField
      }}
    >
      {children}
    </SubCategoryContextField.Provider>
  );
};

export default SubCategoryContextField;
