import React, { useEffect, forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { serialize } from 'object-to-formdata';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';

import useCategory from 'src/hooks/useCategory';


import {
  Box,
  styled,
  Container,
  Card,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Button,
  CircularProgress,
  Slide,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { useNavigate } from 'react-router';
import useNewBlog from 'src/hooks/useNewBlog';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PostPage = styled(Box)(
  () => `
          display: flex; 
          flex-direction: column;
          width: 100%; 
      `
);

const SlideUp = forwardRef(function SlideUp(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddBlogPost = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { categoryList, fetchCategoryList, fetchCourseList } = useCategory();
  const { createNewBlog } = useNewBlog();
  const controller = new AbortController();
  const isBlogCategory= true;
  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchCategoryList(controller.signal,isBlogCategory);
      } catch (error) {
        console.log(error);
      }
    };

    fetchList();
    return () => controller.abort();
  }, []);


  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchCourseList(controller.signal);
      } catch (error) {
        console.log(error);
      }
    };

    fetchList();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchCategoryList(controller.signal, isBlogCategory);
      } catch (error) {
        console.log(error);
      }
    };

    fetchList();

    return () => controller.abort();
  }, []);

  const blogStatus = (status, message) => {
    enqueueSnackbar(message, {
      variant: status ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: SlideUp
    });
  };

  const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        categoryId: null,
        isPublic: false,
        shortDescription: '',
        isBlogRecommended: false,
        isBlogFeatured: false,
        isBlogTrending: false,
        isBlogLatest: false,
        title: '',
        description: '',
        bannerImage: null,
        featureImage: null,
        metaTitle: '',
        metaKeyword: '',
        metaDescription: '',
        ogType: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: null,
        slug: '',
        totalLike: '',
        totalViews: '',
        readTime: ''
      },
      onSubmit: async (values, { setSubmitting }) => {
        try {
          let data = values;
          if (data.categoryId === null) {
            delete data.categoryId;
          }

          const serializeData = serialize({ ...data }, { indices: true });
          const { success,message } = await createNewBlog(serializeData);

          setSubmitting(false);
          if (success) {
            blogStatus(true, 'The blog post is successfully created');
            navigate('/extended-sidebar/management/blog-post/blog-list');
          } else {
            blogStatus(false, `Failed to create blog post ${message}`);
          }
        } catch (error) {
          setSubmitting(false);
          blogStatus(false, error?.data?.message);
        }
      }
    });

  return (
    <>
      <Helmet>
        <title>Add Blog Post</title>
      </Helmet>
      <PageTitleWrapper>
        <Typography variant="h1" component="h1">
          {t('Add Blog Post')}
        </Typography>
      </PageTitleWrapper>
      <PostPage>
        <Container maxWidth="md">
          <Card
            sx={{
              paddingY: 3,
              paddingX: 4,
              mt: 3,
              overflow: 'visible'
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} lg={12}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="select-label">Category</InputLabel>
                    <Select
                      labelId="select-label"
                      id="categoryId"
                      value={values.categoryId}
                      label="categoryId"
                      onChange={(event) =>
                        setFieldValue('categoryId', event.target.value)
                      }
                    >
                      {categoryList.map(({ name, _id }) => (
                        <MenuItem key={_id} value={_id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                margin="normal"
                label="Blog Title"
                value={values.title}
                onChange={handleChange}
                name="title"
                type="text"
                variant="outlined"
              />

              {/* <TextField
                fullWidth
                margin="normal"
                label="Short Description"
                name="shortDescription"
                value={values.shortDescription}
                onChange={handleChange}
                type="text"
                variant="outlined"
              /> */}

              <TextField
                fullWidth
                margin="normal"
                label="Blog Slug"
                value={values.slug}
                onChange={(event) => {
                  const temp = event.target.value;
                  setFieldValue('slug', temp?.trim());
                }}
                name="slug"
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                margin="normal"
                label="Blog Reading Time"
                value={values.readTime}
                onChange={handleChange}
                name="readTime"
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                margin="normal"
                label="Meta Title"
                name="metaTitle"
                value={values.metaTitle}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                margin="normal"
                label="Meta Keyword"
                name="metaKeyword"
                value={values?.metaKeyword}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                margin="normal"
                label="Meta Description"
                name="metaDescription"
                value={values.metaDescription}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />
              {/* meta ogtags */}
              <TextField
                fullWidth
                margin="normal"
                label="Og Title"
                name="ogTitle"
                value={values.ogTitle}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                label="Og Description"
                name="ogDescription"
                value={values.ogDescription}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />

              <FormControl fullWidth margin="normal">
                <Button
                  color={values.ogImage ? 'success' : 'primary'}
                  startIcon={
                    values.ogImage ? <CheckCircleTwoToneIcon /> : null
                  }
                  size="large"
                  variant="outlined"
                  component="label"
                >
                  Select Og Image
                  <input
                    type="file"
                    hidden
                    name="ogImage"
                    accept=".jpg, jpeg, .png, .gif"
                    onChange={(event) =>
                      setFieldValue('ogImage', event.currentTarget.files[0])
                    }
                  />
                </Button>
                <Typography
                  sx={{
                    mt: 1
                  }}
                  variant="caption"
                >
                  (.jpg, jpeg, .png, .gif)
                </Typography>
              </FormControl>
              
              {/* <FormControl fullWidth margin="normal">
                <Button
                  color={values.bannerImage ? 'success' : 'primary'}
                  startIcon={
                    values.bannerImage ? <CheckCircleTwoToneIcon /> : null
                  }
                  size="large"
                  variant="outlined"
                  component="label"
                >
                  Select Banner Image
                  <input
                    type="file"
                    hidden
                    name="bannerImage"
                    accept=".jpg, jpeg, .png, .gif"
                    onChange={(event) =>
                      setFieldValue('bannerImage', event.currentTarget.files[0])
                    }
                  />
                </Button>
                <Typography
                  sx={{
                    mt: 1
                  }}
                  variant="caption"
                >
                  (.jpg, jpeg, .png, .gif)
                </Typography>
              </FormControl> */}

              <FormControl fullWidth margin="normal">
                <Button
                  color={values.featureImage ? 'success' : 'primary'}
                  startIcon={
                    values.featureImage ? <CheckCircleTwoToneIcon /> : null
                  }
                  size="large"
                  variant="outlined"
                  component="label"
                >
                  Select Featured Image ( size 1366X700 )
                  <input
                    type="file"
                    hidden
                    name="featureImage"
                    accept=".jpg, jpeg, .png, .gif"
                    onChange={(event) =>
                      setFieldValue(
                        'featureImage',
                        event.currentTarget.files[0]
                      )
                    }
                  />
                </Button>
                <Typography
                  sx={{
                    mt: 1
                  }}
                  variant="caption"
                >
                  (.jpg, jpeg, .png, .gif)
                </Typography>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <Typography variant="body1" mt={-2}>
                  Blog Description
                </Typography>
                <FormControl fullWidth margin="normal">
                  <CKEditor
                    name="blog Body"
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ data });
                      setFieldValue('description', data);
                    }}
                    data={values.description}
                  />
                </FormControl>
              </FormControl>

              <Box>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  {/* <Grid item xs={6} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="totalLike"
                      label="Likes"
                      type="number"
                      value={values.totalLike}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Views"
                      name="totalViews"
                      type="number"
                      value={values.totalViews}
                      onChange={handleChange}
                    />
                  </Grid> */}
                </Grid>
              </Box>

              <Grid item xs={12} lg={6}>
                <h3>For blogs page only</h3>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogFeatured}
                        onChange={(event) =>
                          setFieldValue('isBlogFeatured', event.target.checked)
                        }
                      />
                    }
                    label="Blog Featured"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogRecommended}
                        onChange={(event) =>
                          setFieldValue(
                            'isBlogRecommended',
                            event.target.checked
                          )
                        }
                      />
                    }
                    label="Blog Recommended"
                  />
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogUpComing}
                        onChange={(event) =>
                          setFieldValue('isBlogUpComing', event.target.checked)
                        }
                      />
                    }
                    label="Blog Upcoming"
                  /> */}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogTrending}
                        onChange={(event) =>
                          setFieldValue('isBlogTrending', event.target.checked)
                        }
                      />
                    }
                    label="Blog Trending"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogLatest}
                        onChange={(event) =>
                          setFieldValue('isBlogLatest', event.target.checked)
                        }
                      />
                    }
                    label="Blog Latest"
                  />
                </FormGroup>
              </Grid>

              <Button
                sx={{
                  mt: 2
                }}
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                size="large"
                fullWidth
              >
                {t('Create Blog Post')}
              </Button>
            </form>
          </Card>
        </Container>
      </PostPage>
      <Footer />
    </>
  );
};

export default AddBlogPost;
