import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Swal from 'sweetalert2';

import Details from './details';

const applyPagination = (items, page, limit) =>
  items.slice(page * limit, page * limit + limit);

const PartnerWithUsLists = ({
  items,
  isFetching,
  fetchListTrigger,
  deletePartnerEntry
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [post, setPost] = useState([]);
  const [show, setShow] = useState(false);

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedItems = applyPagination(items, page, limit);

  const handleClickOpen = (id) => {
    const selectedPost = items.find((item) => item._id === id);
    setPost(selectedPost);
    setShow(true);
  };

  const handleDeletePartner = (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure to delete this partner?',
      text: "You won't be able to revert this!",
      confirmButtonColor: theme.palette.success.main,
      confirmButtonText: 'Delete',
      showCancelButton: true,
      cancelButtonColor: theme.palette.error.main,
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let isFulfilled = false;
        try {
          const { success } = await deletePartnerEntry(id);
          if (success) isFulfilled = true;
        } catch (error) {
          Swal.showValidationMessage('Failed to delete partner');
        }
        return { isFulfilled };
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Partner has been successfully deleted.',
          'success'
        );
        fetchListTrigger();
      }
    });
  };

  console.log(show, post);

  const handleClickClose = () => {
    setShow(false);
  };

  return (
    <>
      {isFetching ? (
        <>
          <Box p={3} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={50} disableShrink thickness={3} />
          </Box>
        </>
      ) : (
        <>
          {paginatedItems.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("We couldn't find any data")}
              </Typography>
            </>
          ) : (
            <Card>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('S.No')}</TableCell>
                      <TableCell align="center">{t('Name')}</TableCell>
                      <TableCell align="center">{t('Mobile')}</TableCell>
                      <TableCell align="center">{t('Email')}</TableCell>
                      <TableCell align="center">{t('Company')}</TableCell>
                      <TableCell align="center">{t('Website URL')}</TableCell>
                      <TableCell align="center">{t('Industry')}</TableCell>
                      <TableCell align="center">{t('Date')}</TableCell>
                      <TableCell align="center">{t('View Details')}</TableCell>
                      <TableCell align="center">{t('Delete')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedItems.map(
                      ({
                        sNo,
                        fullname,
                        _id,
                        email,
                        number,
                        company,
                        websiteURL,
                        industry,
                        createdAt
                      }) => {
                        return (
                          <TableRow hover key={_id}>
                            <TableCell align="center">{sNo}</TableCell>
                            <TableCell align="center">{fullname}</TableCell>
                            <TableCell align="center">{number}</TableCell>
                            <TableCell align="center">{email}</TableCell>
                            <TableCell align="center">{company}</TableCell>
                            <TableCell align="center">{websiteURL}</TableCell>
                            <TableCell align="center">{industry}</TableCell>
                            <TableCell align="center">
                              {moment(createdAt).format('ll')}
                            </TableCell>
                            <TableCell>
                              <Tooltip title={t('View All Details')} arrow>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleClickOpen(_id)}
                                >
                                  <RemoveRedEyeIcon fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Tooltip title={t('Delete Partner Entry')} arrow>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleDeletePartner(_id)}
                                >
                                  <DeleteOutlineIcon fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={items.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </Card>
          )}
        </>
      )}

      {show && (
        <Details post={post} open={show} handleClickClose={handleClickClose} />
      )}
    </>
  );
};

PartnerWithUsLists.propTypes = {
  items: PropTypes.array.isRequired
};

PartnerWithUsLists.defaultProps = {
  items: []
};

export default PartnerWithUsLists;
