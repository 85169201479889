import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialRoleState = {
    roleList: [],
    role: null,
    isFetching: false
}

const RoleContext = createContext({
    ...initialRoleState,
    createRole: () => Promise.resolve(),
    fetchRoleList: () => Promise.resolve()
});

const ACTION_TYPES = {
    ISFETCHING: 'ISFETCHING',
    FETCH: 'FETCH',
    CREATE: 'CREATE',
};


const handler = {
    ISFETCHING: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    FETCH: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    },
    CREATE: (state, payload) => {
        return {
            ...state,
            ...payload
        }
    }
}

const reducer = (state, { type, payload }) => {
    return handler[type] ? handler[type](state, payload) : state;
}

export const RoleContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialRoleState);

    const createRole = async reqData => {
        const { data, success } = await apiCaller('post', '/create', reqData);
        const admin = data ? data.newAdmin : null;
        dispatch({
            type: ACTION_TYPES.CREATE,
            payload: {
                role: admin
            }
        });
        if (success) return Promise.resolve({ success: true });
        return Promise.resolve({ success: false });
    }

    const fetchRoleList = async signal => {
        dispatch({
            type: ACTION_TYPES.ISFETCHING,
            payload: { isFetching: true }
        })
        const { data } = await apiCaller('post', '/fetch', null, null, signal);
        const { admins } = data;
        dispatch({
            type: ACTION_TYPES.FETCH,
            payload: {
                roleList: admins,
                isFetching: false
            }
        });
    }

    return (
        <RoleContext.Provider
            value={{
                ...state,
                createRole,
                fetchRoleList
            }}
        >
            {children}
        </RoleContext.Provider>
    )

}

export default RoleContext;