import React, { useState, useEffect, forwardRef } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useCategory from 'src/hooks/useCategory';
import useSubCategory from 'src/hooks/useSubCategory';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Box,
    Typography,
    FormControl,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Slide,
    Button,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { useSnackbar } from 'notistack';


const SlideUp = forwardRef(function SlideUp(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const CreateEnnopedia = ({ open, setOpen, seasonName, setEnnopediaData, ennopediaData, monthName, setMonthName, update }) => {
    const { categoryList, fetchCategoryList } = useCategory();
    const { subCategoryList, fetchSubCategoryList } = useSubCategory();
    const { enqueueSnackbar } = useSnackbar();
    let token = localStorage.getItem('accessTokenAdmin');
    const controller = new AbortController();
    const [category, setCategory] = useState({
        name: '',
        id: '',
    });
    const [subCategory, setSubCategory] = useState({
        name: '',
        id: '',
    });
    const [episodeImage, setEpisodeImage] = useState(null);
    const [detailImage, setDetailImage]=useState(null);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [shortDescription, setShortDescription]= useState('');
    const [createDate, setCreateDate] = useState(null);
    const [metaTitle, setMetaTitle]= useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword]= useState('');

    const handleClose = () => {
        setOpen(false)
    }
    const blogStatus = (status, message) => {
        enqueueSnackbar(message, {
          variant: status ? 'success' : 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: SlideUp
        });
      };

    const handleCreateEpisode = () => {
        if(episodeImage == null || !title.length ){
            blogStatus(false, 'Fill Complete Innopedia Details')
        }else{
        setEnnopediaData([...ennopediaData, {
            categoryName: category,
            subCategoryName: subCategory,
            title: title,
            description: description,
            slug: slug,
            month: monthName,
            itemIndex: ennopediaData.length ? ennopediaData.length + 1 : 1,
            episodeImage: episodeImage,
            detailImage: detailImage,
            createdDate: moment(createDate).format('MM/DD/YYYY'),
            shortDescription: shortDescription,
            metaTitle: metaTitle,
            metaDescription: metaDescription,
            metaKeyword: metaKeyword,

        }])
        blogStatus(true, 'Innopedia Episode Created!')
        setOpen(false)
    }
    }
    const handleCategory = (e) => {
        setSubCategory({})
        const selectedCategory = e.target.value;
        setCategory({ ...category, name: selectedCategory.name, id: selectedCategory._id })
    }

    const handleUpdateEpisode = () => {
        if (Object.keys(update)?.length) {
            const indexToUpdate = updatedArr.findIndex((item) => item.itemIndex === update.itemIndex);
            if (indexToUpdate !== -1) {
                updatedArr[indexToUpdate].title = title;
                updatedArr[indexToUpdate].description = description;
                updatedArr[indexToUpdate].categoryName = category;
                updatedArr[indexToUpdate].subCategoryName = subCategory;
                updatedArr[indexToUpdate].episodeImage = episodeImage;
                updatedArr[indexToUpdate].detailImage= detailImage;
                updatedArr[indexToUpdate].slug = slug;
                updatedArr[indexToUpdate].createdDate= moment(createDate).format('MM/DD/YYYY');
                updatedArr[indexToUpdate].shortDescription= shortDescription;
                updatedArr[indexToUpdate].metaTitle = metaTitle,
                updatedArr[indexToUpdate].metaDescription = metaDescription,
                updatedArr[indexToUpdate].metaKeyword = metaKeyword,
                setEnnopediaData(updatedArr)
                setOpen(false)
            }
        }
    }

    useEffect(() => {
        const fetchList = async () => {
            try {
                await fetchSubCategoryList(category.id, controller.signal);
            } catch (error) {
                console.log(error);
            }
        };
        fetchList();
        return () => controller.abort();
    }, []);
    useEffect(() => {
        const fetchList = async () => {
            try {
                await fetchCategoryList(controller.signal);
            } catch (error) {
                console.log(error);
            }
        };
        fetchList();
        return () => controller.abort();
    }, []);

    const updatedArr = [...ennopediaData]

    useEffect(() => {
        if (Object.keys(update)?.length) {
            setMonthName(update.year);
            setTitle(update.title);
            setDescription(update.description);
            setCategory({ name: update.categoryName?.name, id: update.categoryName?.id });
            setSubCategory({ name: update.subCategoryName?.name, id: update.subCategoryName?.id });
            setEpisodeImage(update.episodeImage);
            setDetailImage(update.detailImage);
            setSlug(update.slug);
            setCreateDate(update.createdDate);
            setShortDescription(update.shortDescription);
            setMetaTitle(update.metaTitle);
            setMetaDescription(update.metaDescription);
            setMetaKeyword(update.metaKeyword);
        }

    }, [])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "60%",
                            // Set your width here
                        },
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'end', margin: '5px 5px', cursor: 'pointer' }}>
                    <CloseIcon onClick={handleClose} />
                </Box>
                <DialogTitle id="alert-dialog-title" style={{ fontSize: '24px', marginBottom: '-24px', fontWeight: 700 }}>
                    {seasonName}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                label="title"
                                sx={{ my: 3 }}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                style={{width: '49%'}}
                            />
                            <DatePicker
                                label="create date"
                                name="Creat Date"
                                value={createDate}
                                onChange={(e) => setCreateDate(e)}
                                renderInput={(params) => <TextField {...params}
                                    size='small'
                                />}
                            />
                        </Box>

                        <TextField
                            fullWidth
                            id="outlined-size-small"
                            size="small"
                            label="short Description"
                            sx={{ marginTop: '-2px', marginBottom: '30px' }}
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            id="outlined-size-small"
                            size="small"
                            label="Meta Title"
                            sx={{ marginTop: '-2px', marginBottom: '30px' }}
                            value={metaTitle}
                            onChange={(e) => setMetaTitle(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            id="outlined-size-small"
                            size="small"
                            label="Meta Description"
                            sx={{ marginTop: '-2px', marginBottom: '30px' }}
                            value={metaDescription}
                            onChange={(e) => setMetaDescription(e.target.value)}
                        />

                           <TextField
                            fullWidth
                            id="outlined-size-small"
                            size="small"
                            label="Meta Keywords"
                            sx={{ marginTop: '-2px', marginBottom: '30px' }}
                            value={metaKeyword}
                            onChange={(e) => setMetaKeyword(e.target.value)}
                         />

                        <CKEditor
                            name="blog Body"
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ data });
                                setDescription(data);
                            }}
                            data={description}
                        />

                        <TextField
                            fullWidth
                            id="outlined-size-small"
                            size="small"
                            label="slug"
                            sx={{ my: 3 }}
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                        />



                        <Box sx={{ my: 2 }}>
                            <FormControl sx={{ minWidth: '49%', marginRight: '9px' }} size="small">
                                <InputLabel id="demo-select-small-label">Category</InputLabel>
                                <Select
                                    value={category}
                                    label="category"
                                    renderValue={(selected) => selected.name}
                                    onChange={handleCategory}
                                >
                                    {categoryList.map((item) => (
                                        <MenuItem key={item._id} value={item} >
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: '49%' }} size="small">
                                <InputLabel id="demo-select-small-label">Sub-Category</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="sub-category"
                                    value={subCategory}
                                    onChange={(e) => {
                                        setSubCategory({ ...subCategory, name: e.target.value.name, id: e.target.value._id });
                                    }}
                                    renderValue={(selected) => selected.name}
                                >
                                    {subCategoryList
                                        .filter((item) => item.categoryId == category?.id)
                                        .map((item) => (
                                            <MenuItem value={item}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>


                            <FormControl fullWidth margin="normal">
                                <Button
                                    color={episodeImage ? 'success' : 'primary'}
                                    startIcon={
                                        episodeImage ? <CheckCircleTwoToneIcon /> : null
                                    }
                                    size="large"
                                    variant="outlined"
                                    component="label"
                                >
                                   Feature Image (size 861X900 )
                                    <input
                                        type="file"
                                        hidden
                                        name=""
                                        accept=".jpg, jpeg, .png, .gif"
                                        onChange={(event) =>
                                            setEpisodeImage(event.currentTarget.files[0])
                                        }
                                    />
                                </Button>
                                <Typography
                                    sx={{
                                        mt: 1
                                    }}
                                    variant="caption"
                                >
                                    (.jpg, jpeg, .png, .gif)
                                </Typography>
                            </FormControl>
                         {/* For details image  */}
                            <FormControl fullWidth margin="normal">
                                <Button
                                    color={detailImage ? 'success' : 'primary'}
                                    startIcon={
                                        detailImage ? <CheckCircleTwoToneIcon /> : null
                                    }
                                    size="large"
                                    variant="outlined"
                                    component="label"
                                >
                                   Detail Image (size 1366X700 )
                                    <input
                                        type="file"
                                        hidden
                                        name=""
                                        accept=".jpg, jpeg, .png, .gif"
                                        onChange={(event) =>
                                            setDetailImage(event.currentTarget.files[0])
                                        }
                                    />
                                </Button>
                                <Typography
                                    sx={{
                                        mt: 1
                                    }}
                                    variant="caption"
                                >
                                    (.jpg, jpeg, .png, .gif)
                                </Typography>
                            </FormControl>

                        </Box>

                    </Box>
                </DialogContent>
                <DialogActions>
                    {Object.keys(update).length ?
                        <Button variant="contained" onClick={handleUpdateEpisode} autoFocus sx={{ marginBottom: '8px', marginTop: '-15px' }}>
                            Update Episode
                        </Button> :
                        <Button variant="contained"
                        //  disabled={episodeImage == null || !title.length} 
                         onClick={handleCreateEpisode} 
                         autoFocus sx={{ marginBottom: '8px', marginTop: '-15px' }}
                         >
                            Create Episode
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
export default CreateEnnopedia;