import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialSubCategoryState = {
  subCategoryList: [],
  subCategory: null,
  isFetching: false,
  isDeleted: false,
  isUpdated: false,
  subcategoryData: []
};

const SubCategoryContext = createContext({
  ...initialSubCategoryState,
  createSubCategory: () => Promise.resolve(),
  fetchSubCategoryList: () => Promise.resolve(),
  deleteSubCategory: () => Promise.resolve(),
  updateSubCategory: () => Promise.resolve(),
  storeEditSubCategory: () => Promise.resolve()
});

const ACTION_TYPES = {
  ISFETCHING: 'ISFETCHING',
  FETCH: 'FETCH',
  CREATE: 'CREATE'
};

const handler = {
  ISFETCHING: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  FETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  CREATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
};

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const SubCategoryContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialSubCategoryState);

  const createSubCategory = async (reqData) => {
    const { data, success } = await apiCaller(
      'post',
      '/sub-category/create',
      reqData
    );
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        subCategory: data
      }
    });
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const fetchSubCategoryList = async (signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });
    const { data } = await apiCaller(
      'post',
      '/sub-category/fetch',
      null,
      null,
      signal
    );
    const { allData } = data;
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        subCategoryList: allData,
        isFetching: false
      }
    });
  };

  const deleteSubCategory = async (id) => {
    const { success } = await apiCaller('delete', `/sub-category/${id}`);
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: {
        isDeleted: true
      }
    });
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const updateSubCategory = async (reqData) => {
    const { data, success } = await apiCaller('put', '/sub-category/', reqData);
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        subCategory: data
      }
    });
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const storeEditSubCategory = async (reqData) => {
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        subcategoryData: reqData
      }
    });
  };
  return (
    <SubCategoryContext.Provider
      value={{
        ...state,
        createSubCategory,
        fetchSubCategoryList,
        deleteSubCategory,
        updateSubCategory,
        storeEditSubCategory
      }}
    >
      {children}
    </SubCategoryContext.Provider>
  );
};

export default SubCategoryContext;
