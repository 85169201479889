import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const AnalyticsCard = ({ title, count, color, setViewDetails, paramType, viewDetails, image, setGraphTitle, setBarColor }) => {
  const handleShowDeatils = () => {
    setViewDetails(paramType);
    setGraphTitle(title);
    setBarColor(color)
  }
  return (
    <Card style={{
      backgroundColor: 'white',
      borderRadius: '5px',
      margin: '0.5%',
      width: '24%',
      // marginRight: '6px',
      boxShadow: viewDetails === paramType ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : ''
    }}
    >
      <Card.Body style={{ padding: '10px 7px' }}>
        <Card.Header style={{ fontSize: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {title}
          </div>
          <img src={image} />
        </Card.Header>
        <div style={{ margin: '5px 0px', fontSize: '20px', fontWeight: 700 }}>
          {count}
        </div>
        <Button
          variant="outline-light"
          style={{
            background: 'white',
            border: `1px solid ${color}`,
            borderRadius: '3px',
            padding: '5px',
            cursor: 'pointer',
            color: `${color}`
          }}
          onClick={handleShowDeatils}
        >
          View Detail
        </Button>
      </Card.Body>
    </Card>
  );
}
export default AnalyticsCard;