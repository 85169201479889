import PropTypes from 'prop-types';
import { Outlet, Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

import { Box } from '@mui/material';

const BaseLayout = ({ children }) => {

  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/account/login" />;
  }
  
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%'
      }}
    >
      {children || <Outlet />}
    </Box>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
