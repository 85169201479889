import React, { useState ,forwardRef} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  Card,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
  TablePagination,
  Box,
  Switch,
  Link,
  InputAdornment,
  TextField,
  Divider,
  useTheme,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Slide,
} from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { serialize } from 'object-to-formdata';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import IosShareTwoToneIcon from '@mui/icons-material/IosShareTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import useCategory from 'src/hooks/useCategory';
import useNewBlog from 'src/hooks/useNewBlog';
import UpdatePost from './updateBlogPost';
import { useSnackbar } from 'notistack';
const SlideUp = forwardRef(function SlideUp(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const tags = [
  {
    name: 'Published',
    value: 'isPublic'
  },
  {
    name: 'Blog Recommended',
    value: 'isBlogRecommended'
  },
  {
    name: 'Blog UpComing',
    value: 'isBlogUpComing'
  },
  {
    name: 'Blog Featured',
    value: 'isBlogFeatured'
  },
  {
    name: 'Blog Latest',
    value: 'isBlogLatest'
  },
  {
    name: 'Blog Trending',
    value: 'isBlogTrending'
  }
];

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, isFetching } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLeftIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        {isFetching ? (
          <CircularProgress size="1rem" thickness={5} />
        ) : (
          <>
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </>
        )}
      </IconButton>
    </Box>
  );
};

// const PreviewLink = forwardRef((props, ref) => (
//   <RouterLink ref={ref} {...props} />
// ));

const applyPagination = (items, page, limit) =>
  items.slice(page * limit, page * limit + limit);

const applyFilters = (items, query, dateData, category, tag) => {
  return items.filter((item) => {
    let match = true;
    if (query) {
      let containedQuery = false;
      if (item.title.toLowerCase().includes(query.toLowerCase())) {
        containedQuery = true;
      }
      if (!containedQuery) {
        match = false;
      }
    }

    if (dateData) {
      let containedQuery = false;
      if (
        new Date(`${item ? item.createdAt : ''}`).getMonth() === dateData.$M
      ) {
        containedQuery = true;
      }
      if (!containedQuery) {
        match = false;
      }
    }


    if (category) {
      let containedName = false;
      const { categoryId } = item;
      if (categoryId) {
        if (categoryId.name === category) {
          containedName = true;
        }
        if (!containedName) {
          match = false;
        }
      }
    }

    if (tag) {
      let containedTag = false;
      if (item[tag]) {
        containedTag = true;
      }

      if (!containedTag) {
        match = false;
      }
    }

    return match;
  });
};
const Lists = ({ items, isFetching }) => {
  const theme = useTheme();
  const { updateNewBlog, deleteNewBlog, isFetchingNext,fetchNewBlogList } = useNewBlog();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  // const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [post, setPost] = useState(null);
  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [tag, setTag] = useState('');
  const { categoryList } = useCategory();
  const [featurelimit, setFeatureLimit]= useState(false);
 
  const [dateValue, setDateValue] = useState(null);
  const controller = new AbortController();
  const { enqueueSnackbar } = useSnackbar();

  const fetchList = async () => {
    try {
      await fetchNewBlogList(controller.signal);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = (id) => {
    const selectedPost = items.find((item) => item._id === id);
    setPost(selectedPost);
    setShow(true);
  };

  const handleClickClose = () => {
    setShow(false);
  };

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
    setPage(0);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);

    const found = categoryList.find(
      (element) => e.target.value === element.name
    );
    setCategoryId(found._id);

    setPage(0);
  };

  const handleTagChange = (e) => {
    setTag(e.target.value);
    setPage(0);
  };

  const handleReset = () => {
    setQuery('');
    setCategory('');
    setTag('');
    setDateValue(null);
  };

  const filteredItems = applyFilters(
    items,
    query,
    dateValue,
    category,
    tag
  );

  const paginatedItems = applyPagination(filteredItems, page, limit);
  const blogStatus = (status, message) => {
    enqueueSnackbar(message, {
      variant: status ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: SlideUp
    });
  };
  const handleActivationToggle = async (
    status,
    id,
    trendingNow,
    newRelease,
    influencerPick,
    top10Pick,
    upComing,
    popularPicks,
    blogFeatured,
    blogRecommended,
    blogUpComing,
    blogTrending,
    blogLatest
  ) => {
    try {
      const serializeData = serialize({
        isPublic: status,
        blogId: id,
        isTrendingNow: trendingNow,
        isNewRelease: newRelease,
        isInfluencerPick: influencerPick,
        isTop10Pick: top10Pick,
        isUpComing: upComing,
        isPopularPicks: popularPicks,
        isBlogFeatured: blogFeatured,
        isBlogRecommended: blogRecommended,
        isBlogUpComing: blogUpComing,
        isBlogTrending: blogTrending,
        isBlogLatest: blogLatest
      });
      await updateNewBlog(serializeData);
    } catch (error) {
       setFeatureLimit(true);
       blogStatus(false, error?.data?.message);
       fetchList();
       setFeatureLimit(false);
    }
  };

  const handleDelete = (blogId) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure to delete this blog post?',
      text: "You won't be able to revert this!",
      confirmButtonColor: theme.palette.success.main,
      confirmButtonText: 'Delete',
      showCancelButton: true,
      cancelButtonColor: theme.palette.error.main,
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let isFulfilled = false;
        try {
          const { success } = await deleteNewBlog(blogId);
          if (success) isFulfilled = true;
        } catch (error) {
          Swal.showValidationMessage('Failed to delete blog post');
        }
        return { isFulfilled };
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Post has been successfully blog deleted.',
          'success'
        );
        fetchList();
      }
    });
  };

  return (
    <>
      <Card>
        <Box
          sx={{
            pt: 2,
            px: 2
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={category}
                  label="Category"
                  onChange={handleCategoryChange}
                >
                  {categoryList.map(({ name, _id }) => (
                    <MenuItem key={_id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Tags</InputLabel>
                <Select value={tag} label="Tags" onChange={handleTagChange}>
                  {tags.map(({ name, value }) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year', 'month']}
                  label="Year and Month"
                  minDate={dayjs('2012-03-01')}
                  maxDate={dayjs('2023-06-01')}
                  value={dateValue}
                  onChange={(newValue) => {
                    setDateValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <TextField
            sx={{
              m: 0
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder={t('Search by Title')}
            value={query}
            size="small"
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleReset} size="medium">
              Reset Filters
            </Button>
          </Box>
        </Box>
        <Divider />
        {isFetching ? (
          <>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={50} disableShrink thickness={3} />
            </Box>
          </>
        ) : (
          <>
            {paginatedItems.length === 0 ? (
              <>
                <Typography
                  sx={{
                    py: 10
                  }}
                  variant="h3"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  {t("We couldn't find any posts")}
                </Typography>
              </>
            ) : (
              <>
                <TableContainer>
                  <Table sx={{ minWidth: 1100 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{t('S.No')}</TableCell>
                        <TableCell align="center">{t('Actions')}</TableCell>
                        <TableCell align="center">{t('Title')}</TableCell>
                        <TableCell align="center">{t('Category')}</TableCell>
                        <TableCell align="center">{t('Image')}</TableCell>
                        <TableCell align="center">Date</TableCell>
                        {/* <TableCell align="center">{t('Actions')}</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedItems.map(
                        ({
                          sNo,
                          _id,
                          categoryId,
                          featureImage,
                          isPublic,
                          title,
                          createdAt,
                          isTrendingNow,
                          isNewRelease,
                          isInfluencerPick,
                          isTop10Pick,
                          isUpComing,
                          isPopularPicks,
                          isBlogFeatured,
                          isBlogRecommended,
                          isBlogUpComing,
                          isBlogTrending,
                          isBlogLatest
                          // slug
                        }) => {
                          return (
                            <TableRow hover key={_id}>
                              <TableCell align="center">{sNo}</TableCell>
                              <TableCell align="center">
                                <Typography
                                  noWrap
                                  justifyContent="space-around"
                                  // textAlign="center"
                                >
                                  <Tooltip title={t('Status')} arrow>
                                    <Switch
                                      onChange={(event) =>
                                        handleActivationToggle(
                                          event.target.checked,
                                          _id,
                                          isTrendingNow,
                                          isNewRelease,
                                          isInfluencerPick,
                                          isTop10Pick,
                                          isUpComing,
                                          isPopularPicks,
                                          isBlogFeatured,
                                          isBlogRecommended,
                                          isBlogUpComing,
                                          isBlogTrending,
                                          isBlogLatest,
                                        )
                                      }
                                      size="medium"
                                      color="primary"
                                      defaultChecked={isPublic && !featurelimit}
                                    />
                                  </Tooltip>
                                  <Tooltip title={t('Edit')} arrow>
                                    <IconButton
                                      color="primary"
                                      onClick={() => handleClickOpen(_id)}
                                    >
                                      <EditTwoToneIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t('Delete')} arrow>
                                    <IconButton
                                      onClick={() => handleDelete(_id)}
                                      color="primary"
                                    >
                                      <DeleteTwoToneIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                              </TableCell>
                              <TableCell align="center">{title}</TableCell>
                              <TableCell align="center">
                                {categoryId && categoryId.name}
                              </TableCell>
                              <TableCell align="center">
                                <Link href={featureImage} target="_blank">
                                  <IosShareTwoToneIcon />
                                </Link>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Link
                                  to={`/preview/${slug}`}
                                  target="_blank"
                                  component={PreviewLink}
                                >
                                  <IosShareTwoToneIcon />
                                </Link>
                              </TableCell> */}
                              <TableCell align="center">
                                {new Date(createdAt)
                                  .toISOString()
                                  .substring(0, 10)}
                              </TableCell>
                              {/* <TableCell>
                                <Typography
                                  noWrap
                                  justifyContent="space-around"
                                >
                                  <Tooltip title={t('Status')} arrow>
                                    <Switch
                                      onChange={(event) =>
                                        handleActivationToggle(
                                          event.target.checked,
                                          _id,
                                          isTrendingNow,
                                          isNewRelease,
                                          isInfluencerPick,
                                          isTop10Pick,
                                          isUpComing,
                                          isPopularPicks,
                                          isBlogFeatured,
                                          isBlogRecommended,
                                          isBlogUpComing,
                                          isBlogTrending,
                                          isBlogLatest
                                        )
                                      }
                                      size="medium"
                                      color="primary"
                                      defaultChecked={isPublic}
                                    />
                                  </Tooltip>
                                  <Tooltip title={t('Edit')} arrow>
                                    <IconButton
                                      color="primary"
                                      onClick={() => handleClickOpen(_id)}
                                    >
                                      <EditTwoToneIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t('Delete')} arrow>
                                    <IconButton
                                      onClick={() => handleDelete(_id)}
                                      color="primary"
                                    >
                                      <DeleteTwoToneIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                              </TableCell> */}
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box p={2} sx={{ position: 'fixed', bottom: '0', background:'#ffff', textAlign: "center", width:"71.4%",borderRadius:"8px", overflow:"hidden"}}>
                  <TablePagination
                    component="div"
                    count={filteredItems.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    ActionsComponent={(props) => (
                      <TablePaginationActions
                        {...props}
                        isFetching={isFetchingNext}
                      />
                    )}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[20, 50, 100]}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Card>
      {show && (
        <>
        <UpdatePost
          post={post}
          open={show}
          handleClickClose={handleClickClose}
        />
        </>
      )}
    </>
  );
};

Lists.propTypes = {
  items: PropTypes.array.isRequired
};

Lists.defaultProps = {
  items: []
};

export default Lists;
