import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialBlogState = {
  blogList: [],
  blog: null,
  exportUrl: null,
  isFetching: false,
  isFetchingNext: false,
  isFetchPartnerWithUs: false,
  partnerWithUsData: []
};

const BlogContext = createContext({
  ...initialBlogState,
  createBlog: () => Promise.resolve(),
  fetchBlogList: () => Promise.resolve(),
  updateBlog: () => Promise.resolve(),
  deleteBlog: () => Promise.resolve(),
  fetchBlog: () => Promise.resolve(),
  fetchNextBlogList: () => Promise.resolve(),
  fetchPartnerWithUsData: () => Promise.reject(),
  exportPartnerWithUsData: () => Promise.resolve(),
  deletePartnerEntry: () => Promise.resolve()
});

const ACTION_TYPES = {
  ISFETCHING: 'ISFETCHING',
  FETCH: 'FETCH',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  EXPORT: 'EXPORT'
};

const handler = {
  ISFETCHING: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  FETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  CREATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  EXPORT: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
};

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const BlogContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialBlogState);

  const createBlog = async (reqData) => {
    const { data, success } = await apiCaller('post', '/blog/create', reqData);
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: {
        blog: data
      }
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const updateBlog = async (reqData) => {
    const { data, success } = await apiCaller('put', '/blog', reqData);
    dispatch({
      type: ACTION_TYPES.UPDATE,
      payload: {
        blog: data
      }
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const fetchBlogList = async (reqData, signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });
    const { data } = await apiCaller(
      'post',
      '/blog/fetch',
      reqData,
      null,
      signal
    );
    const { allData } = data;
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        blogList: allData,
        isFetching: false
      }
    });
  };

  const fetchNextBlogList = async (reqData, signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetchingNext: true }
    });
    const { data } = await apiCaller(
      'post',
      '/blog/fetch',
      reqData,
      null,
      signal
    );
    const { allData } = data;
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        blogList: [...state.blogList, ...allData],
        isFetchingNext: false
      }
    });
  };

  const fetchPartnerWithUsData = async (startDate, endDate, signal) => {
    const requestData = { startDate, endDate }; 
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetchPartnerWithUs: true }
    });
    const { data } = await apiCaller(
      'post',
      '/launch-your-product',
      requestData,
      null,
      signal
    );
    const { list } = data;
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        partnerWithUsData: list,
        isFetchPartnerWithUs: false
      }
    });
  };

  const exportPartnerWithUsData = async (startDate, endDate,signal) => {
    const requestData = { startDate, endDate }; 
    const data = await apiCaller(
      'post',
      '/launch-your-product/export',
      requestData,
      null,
      signal,
      'arraybuffer'
    );
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const blobURL = URL.createObjectURL(blob);
    dispatch({
      type: ACTION_TYPES.EXPORT,
      payload: {
        exportUrl: blobURL
      }
    });
  };

  const fetchBlog = async (id, signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });

    const { data } = await apiCaller('get', `/blog/${id}`, null, null, signal);
    const { blog } = data;

    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: { blog, isFetching: false }
    });
  };

  const deleteBlog = async (id) => {
    const { success } = await apiCaller('delete', `/blog/${id}`);
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const deletePartnerEntry = async (id) => {
    const { success } = await apiCaller('delete', `/launch-your-product/${id}`);
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  return (
    <BlogContext.Provider
      value={{
        ...state,
        createBlog,
        fetchBlogList,
        updateBlog,
        deleteBlog,
        fetchBlog,
        fetchNextBlogList,
        fetchPartnerWithUsData,
        exportPartnerWithUsData,
        deletePartnerEntry
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export default BlogContext;
