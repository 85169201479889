// import { Link as RouterLink } from 'react-router-dom';
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Slide
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSnackbar } from 'notistack';

const SlideUp = forwardRef(function SlideUp(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CardImg = styled(Card)(
  ({ theme }) => `
    width: 90px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: ${theme.colors.alpha.white[100]};
    margin: 0 ${theme.spacing(1)};
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['all'])};

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);
const BottomWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};
    display: flex;
    align-items: center;
    justify-content: center;
`
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function RegisterBasic() {
  const { t } = useTranslation();
  const { signUp } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigation= useNavigate();

  const blogStatus = (status, message) => {
    enqueueSnackbar(message, {
      variant: status ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: SlideUp
    });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    role: Yup.string().required('Role is required'),
    registerEmail: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    terms: Yup.boolean()
      .oneOf([true], 'You must agree to our terms and conditions')
      .required('You must agree to our terms and conditions')
  });

  return (
    <>
      <Helmet>
        <title>Register - Basic</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Create account')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign up for an account.')}
                </Typography>

                <Formik
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    role: '',
                    registerEmail: '',
                    password: '',
                    confirmPassword: '',
                    terms: true
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const data = values;
                      await signUp({ ...data });
                      blogStatus(true, 'Created User Successfully!');
                      navigation('/extended-sidebar/management/admin/list')
                    } catch (e) {
                      blogStatus(false, e.data.message);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ display: 'flex' }}>
                        <TextField
                          error={Boolean(touched.firstName && errors.firstName)}
                          fullWidth
                          margin="normal"
                          helperText={touched.lastName && errors.firstName}
                          label={t('First Name')}
                          name="firstName"
                          onChange={handleChange}
                          type="text"
                          value={values.firstName}
                          variant="outlined"
                          sx={{ paddingRight: '5px' }}
                        />
                        <TextField
                          error={Boolean(touched.lastName && errors.lastName)}
                          fullWidth
                          margin="normal"
                          helperText={touched.lastName && errors.lastName}
                          label={t('Last Name')}
                          name="lastName"
                          onChange={handleChange}
                          type="text"
                          value={values.lastName}
                          variant="outlined"
                        />
                      </Box>
                      <Box sx={{ marginTop: '8px' }}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.role && errors.role)}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Role
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.role}
                            name="role"
                            label="Role"
                            onChange={handleChange}
                          >
                            <MenuItem value={'ADMIN'}>Admin</MenuItem>
                            <MenuItem value={'VIEWER'}>Viewer</MenuItem>
                          </Select>
                          <FormHelperText>
                            {touched.role && errors.role}
                          </FormHelperText>
                        </FormControl>
                      </Box>

                      <TextField
                        error={Boolean(
                          touched.registerEmail && errors.registerEmail
                        )}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.registerEmail && errors.registerEmail
                        }
                        label={t('Email')}
                        name="registerEmail"
                        onChange={handleChange}
                        type="email"
                        value={values.registerEmail}
                        variant="outlined"
                      />
                      <Box sx={{ display: 'flex' }}>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          margin="normal"
                          helperText={touched.password && errors.password}
                          label={t('Password')}
                          name="password"
                          onChange={handleChange}
                          type="password"
                          value={values.password}
                          variant="outlined"
                          sx={{ paddingRight: '5px' }}
                        />
                        <TextField
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                          fullWidth
                          margin="normal"
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          label={t('confirm Password')}
                          name="confirmPassword"
                          onChange={handleChange}
                          type="password"
                          value={values.confirmPassword}
                          variant="outlined"
                        />
                      </Box>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.terms}
                              name="terms"
                              color="primary"
                              onChange={handleChange}
                            />
                          }
                          label={
                            <>
                              <Typography variant="body2">
                                {t('I accept the')}{' '}
                                <Link component="a" href="#">
                                  {t('terms and conditions')}
                                </Link>
                                .
                              </Typography>
                            </>
                          }
                        />
                      </Box>

                      {Boolean(touched.terms && errors.terms) && (
                        <FormHelperText error>{errors.terms}</FormHelperText>
                      )}

                      <Box display="flex" justifyContent="center" marginTop={2}>
                        {Boolean(errors.submit) && (
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        )}
                      </Box>

                      <Button
                        sx={{
                          mt: 0
                        }}
                        color="primary"
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                      >
                        {t('Create a New User')}
                      </Button>
                    </form>
                  )}
                </Formik>
              </Box>
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default RegisterBasic;
