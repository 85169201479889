import React, { useState, useEffect } from 'react';
import useCategory from 'src/hooks/useCategory';
import useSubCategory from 'src/hooks/useSubCategory';
import { Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const CategoryChartGraph = ({ barColor }) => {
  const { categoryList, fetchCategoryList } = useCategory();
  const { subCategoryList, fetchSubCategoryList } = useSubCategory();
  let token = localStorage.getItem('accessTokenAdmin');
  const controller = new AbortController();
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCatData, setSubCatData] = useState({});
  const [categoryMonthlyData, setCategoryMonthlyData] = useState([]);
  const [showGraphDayWise, setShowGraphDayWise] = useState(false);
  const [monthName, setMonthName] = useState('');
  const [myDayWiseData, setMyDayWiseData] = useState({});
  const [categoryPerDay, setCategoryPerDay] = useState([]);
  const [loader, setLoader] = useState();
  const prepareSubcategoryData = (subCategoryData) => {
    const showCategory = subCategoryData.filter(
      (val) => val.subCategoryId === subCategory
    );
    if (showCategory.length) {
      showCategory.map((val) => {
        setSubCatData({ ...val.months });
      });
    } else {
      setCategoryMonthlyData([]);
    }
  };
  const handleSetDayWiseData = () => {
    setCategoryPerDay([
      ...Object.keys(myDayWiseData).map((val) => myDayWiseData[val]?.count)
    ]);
  };
  const fetchSubCategoryData = async () => {
    setLoader(true);
    await axios
      .get(
        `${process.env.REACT_APP_URL}/analytics/subCategoryDashboardData?id=${category}`,
        {
          headers: {
            Authorization: `token ${token}`
          }
        }
      )
      .then((res) => {
        setLoader(false);
        prepareSubcategoryData(res.data.data.subCategory);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  const handleMonthlyData = () => {
    let monthData = [];
    Object.keys(subCatData).map((val) => {
      monthData.push(subCatData[val].count);
    });
    setCategoryMonthlyData([...monthData]);
  };
  const onPostMonthBarClick = (event, chartContext, config) => {
    setMonthName(months[config.dataPointIndex]);
    setShowGraphDayWise(true);
  };
  const onPostDayBarClick = (event, chartContext, config) => {
    setShowGraphDayWise(false);
  };

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchCategoryList(controller.signal);
      } catch (error) {
        console.log(error);
      }
    };
    fetchList();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchSubCategoryList(category, controller.signal);
      } catch (error) {
        console.log(error);
      }
    };
    fetchList();
    return () => controller.abort();
  }, []);

  const options = {
    chart: {
      toolbar: {
        show: false
      },
      id: 'basic-bar',
      events: {
        dataPointSelection: function (event, chartContext, config) {
          onPostMonthBarClick(event, chartContext, config);
        }
      }
    },
    xaxis: {
      categories: Object.keys(subCatData) || []
    },
    yaxis: {
      title: {
        text: 'Number of Posts Per Month'
      }
    },
    fill: {
      colors: [barColor]
    }
  };

  const series = [
    {
      name: 'Posts',
      data: categoryMonthlyData
    }
  ];

  const optionsPerDay = {
    chart: {
      toolbar: {
        show: false
      },
      id: 'basic-bar',
      events: {
        dataPointSelection: function (event, chartContext, config) {
          onPostDayBarClick({ event, chartContext, config });
        }
      }
    },
    xaxis: {
      categories: Object.keys(myDayWiseData) || []
    },
    yaxis: {
      title: {
        text: 'Number of Posts Per day'
      }
    },
    fill: {
      colors: [barColor]
    }
  };

  const seriesPerDay = [
    {
      name: 'Visit',
      data: categoryPerDay
    }
  ];

  useEffect(() => {
    if (subCategory) {
      fetchSubCategoryData();
      setShowGraphDayWise(false);
    }
  }, [subCategory]);

  useEffect(() => {
    if (Object.keys(subCatData).length) {
      handleMonthlyData();
    }
  }, [subCatData]);

  useEffect(() => {
    setMyDayWiseData({ ...subCatData[monthName]?.days });
  }, [monthName]);

  useEffect(() => {
    if (Object.keys(myDayWiseData).length) {
      handleSetDayWiseData();
    }
  }, [myDayWiseData]);
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h3>Category</h3>
        <Box>
          <FormControl
            margin="normal"
            sx={{ m: 1, minWidth: 150 }}
            size="small"
          >
            <InputLabel id="select-label">Category</InputLabel>
            <Select
              labelId="select-label"
              id="categoryId"
              value={category}
              label="categoryId"
              onChange={(event) => setCategory(event.target.value)}
              // onChange={(e)=> handleSetCategory(e)}
            >
              {categoryList.map(({ name, _id }) => (
                <MenuItem key={_id} value={_id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            sx={{ m: 1, minWidth: 150 }}
            size="small"
          >
            <InputLabel id="select-label">Sub Category</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              name="subCategoryId"
              value={subCategory}
              label="Sub Category"
              onChange={(event) => {
                setSubCategory(event.target.value);
              }}
            >
              {subCategoryList
                .filter((item) => item.categoryId === category)
                .map(({ name, _id }) => (
                  <MenuItem key={_id} value={_id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <div className="chart" style={{ width: '100%' }}>
        {!showGraphDayWise ? (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        ) : (
          <ReactApexChart
            options={optionsPerDay}
            series={seriesPerDay}
            type="bar"
            height={350}
          />
        )}
      </div>
    </>
  );
};

export default CategoryChartGraph;
