import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChartComponent = ({ series, onBarClick, categories }) => {
  const options = {
    chart: {
      id: 'basic-bar',
        toolbar: {
            show: false
          },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          onBarClick({ event, chartContext, config });
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      labels: {
        format: 'MM yyyy'
      },
      categories: categories
    }
  };
  return (
    <>
      <div className="chart" style={{width: '100%'}}>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </>
  );
};

export default BarChartComponent;