import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, TextField } from '@mui/material';
import axios from 'axios';
import AnalyticsCard from './card';
import ChartComponent from './chartComponent'
import MonthlyChartComponent from './monthlyChartComponent'
import PostChartComponent from './PostChart'
import UserSession from './sessionChart'
import DatePicker from '@mui/lab/DatePicker';


const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const mName = new Date().getMonth()

export default function Analytics() {
  const [selectYear, setSelectYear] = useState(new Date().getFullYear());
  const [viewDetails, setViewDetails] = useState('UserLogin');
  const [selectMonth, setSelectMonth] = useState(month[mName]);
  const [filteredValuesByMonth, setFilteredValuesByMonth] = useState({});
  const [data, setData] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [graphTitle, setGraphTitle] = useState('User Login');
  const [loader, setLoader] = useState();
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [toDate, setToDate] = useState(new Date());
  const [barColor, setBarColor]= useState('#a01c24');
  let token = localStorage.getItem("accessTokenAdmin");
  const handleSelect = (e) => {
    setSelectYear(e.target.value)
  }
  const handleFetchGraphData = async () => {
    setLoader(true);
    axios.get(`${process.env.REACT_APP_URL}/analytics/typeWiseDashboardData?type=${viewDetails}&year=${selectYear}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
      .then((res) => {
        setLoader(false);
        setData({ ...res.data.data });
      })
      .catch((error) => {
        setLoader(false);
        console.error(error)
      })
  }

  const handleGetDesiredData = async () => {
    axios.get(`${process.env.REACT_APP_URL}/analytics/totalDashboardData?startDate=${fromDate.toISOString()}&endDate=${toDate.toISOString()}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
      .then((res) => {
        setCardDetails([...res.data.data]);
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    handleGetDesiredData();
  }, [viewDetails])
  useEffect(() => {
    
    if (viewDetails !== 'Blog' && viewDetails !== 'AvgUserTime') {
        handleFetchGraphData();
    }

  }, [selectYear, viewDetails])


  useEffect(() => {
    if (Object.keys(data).length === 0) {
      return;
    }
    const filteredValues = Object.keys(data).filter((val) => val === selectMonth);
    const dataArr = filteredValues
      .filter(key => Object.prototype.hasOwnProperty.call(data, key))
      .map(key => data[key]);

    setFilteredValuesByMonth({ ...dataArr[0] });
  }, [data, selectMonth]);

 
  return (
    <Box style={{ width: '98%', margin: 'auto' }}>
      <h2>Dashboard</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2px' }}>
        <h4>{graphTitle}</h4>
        <div style={{display: 'flex', alignItems:'end'}}>
          <div style={{width: '150px',height: '22px',marginRight: '1px',border: 'none'}}>
                <DatePicker
                  label="From Date"
                  name="From Date"
                  value={fromDate}
                  onChange={(e) =>  setFromDate(e)}
                  renderInput={(params) => <TextField {...params}  
                  size='small'
                  />}
                  />  
          </div>
          <div style={{ width: '150px',height: '22px',marginRight: '1px',border: 'none' }}>
            <DatePicker
              label="To Date"
              name="To Date"
              value={toDate}
              onChange={(e) => setToDate(e)}
              renderInput={(params) => <TextField {...params} 
              size='small'
              />}
            /> 
          </div>
          <div style={{
                width: '44px',height: '22px',
              }}>
            <img
            style={{width: '35.5px',borderRadius:'4px'}}
              src='/static/images/logo/analyticsSearch.svg'
              alt=""
              onClick={handleGetDesiredData}
            />
          </div>
        </div>
      </div>
      <Box style={{ display: 'flex', flexWrap: 'wrap', width: '100%'}}>
        {cardDetails.map((val, index) => {
          return (
            <AnalyticsCard
              title={val.title}
              count={val.count}
              color={val.color}
              paramType={val.type}
              image={val.img}
              setViewDetails={setViewDetails}
              viewDetails={viewDetails}
              setGraphTitle={setGraphTitle}
              setBarColor={setBarColor}
            />
          )
        })}
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3>Yearly Graph</h3>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel id="demo-select-small-label">Year</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectYear}
            label="Year"
            onChange={(e) => handleSelect(e)}
          >
            <MenuItem value="2018">2018</MenuItem>
            <MenuItem value="2019">2019</MenuItem>
            <MenuItem value="2020">2020</MenuItem>
            <MenuItem value="2021">2021</MenuItem>
            <MenuItem value="2022">2022</MenuItem>
            <MenuItem value="2023">2023</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {viewDetails === 'Blog' ?
        <PostChartComponent selectYear={selectYear} barColor={barColor} /> :
        (viewDetails=== 'AvgUserTime'? <UserSession />:
        <>
          <ChartComponent yearlyData={data} loader={loader} graphTitle={graphTitle} barColor={barColor}/>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>Monthly Graph</h3>
            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
              <InputLabel id="demo-select-small-label">Month</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectMonth}
                label="Month"
                onChange={(e) => setSelectMonth(e.target.value)}
              >
                <MenuItem value="Jan">Jan</MenuItem>
                <MenuItem value="Feb">Feb</MenuItem>
                <MenuItem value="Mar">Mar</MenuItem>
                <MenuItem value="Apr">Apr</MenuItem>
                <MenuItem value="May">May</MenuItem>
                <MenuItem value="Jun">Jun</MenuItem>
                <MenuItem value="Jul">Jul</MenuItem>
                <MenuItem value="Aug">Aug</MenuItem>
                <MenuItem value="Sep">Sep</MenuItem>
                <MenuItem value="Oct">Oct</MenuItem>
                <MenuItem value="Nov">Nov</MenuItem>
                <MenuItem value="Dec">Dec</MenuItem>
              </Select>
            </FormControl>

          </Box>
          {Object.keys(data).length > 0 && (
            <MonthlyChartComponent 
            monthlyData={filteredValuesByMonth} 
            selectMonth={selectMonth} 
            loader={loader} 
            graphTitle={graphTitle} 
            barColor={barColor} 
            />
          )}
        </>)}
    </Box>
  );
}
