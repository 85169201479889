import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import KeyboardAltTwoToneIcon from '@mui/icons-material/KeyboardAltTwoTone';
import ViewCarouselTwoToneIcon from '@mui/icons-material/ViewCarouselTwoTone';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { AnalyticsRounded } from '@mui/icons-material';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
// import useAuth from 'src/hooks/useAuth';
import { ACCESS_ROLES } from 'src/constant/role';


// const { user }= useAuth();
const rightForOnlySuperUser=[ ACCESS_ROLES.SUPER_ADMIN ]
const rightForOnlyAdminUser=[ ACCESS_ROLES.ADMIN ]
const rightForAllUser=[ ACCESS_ROLES.SUPER_ADMIN ,ACCESS_ROLES.ADMIN, ACCESS_ROLES.VIEWER ]
const rightForAdminAndSuperUser= [ACCESS_ROLES.SUPER_ADMIN, ACCESS_ROLES.ADMIN]

const menuItems = [
  {
    heading: 'Management',
    items: [
      {
        name: 'Analytics',
        icon: AnalyticsRounded,
        link: '/extended-sidebar/management/analytics',
        access: rightForAllUser,
      },
      {
        name: 'Partner with us',
        icon: AnalyticsRounded,
        link: '/extended-sidebar/management/partner-with-us',
        access: rightForAllUser,
      },
      {
        name: 'Campaigns',
        icon: AnalyticsRounded,
        link: '/extended-sidebar/management/campaigns',
        access: rightForAllUser,
      },
      {
        name: 'Category',
        icon: CategoryTwoToneIcon,
        link: '/extended-sidebar/management/category',
        access: rightForAllUser,
        items: [
          {
            name: 'Add Category',
            link: 'management/category/add-category',
            access: rightForAdminAndSuperUser
          },
          {
            name: 'List',
            link: 'management/category/list',
            access: rightForAllUser
          }
        ]
      },
      {
        name: 'Sub Category',
        icon: AccountTreeTwoToneIcon,
        link: '/extended-sidebar/management/sub-category',
        access: rightForAllUser,
        items: [
          {
            name: 'Add Sub Category',
            link: 'management/sub-category/add-sub-category',
            access: rightForAdminAndSuperUser
          },
          {
            name: 'List',
            link: 'management/sub-category/list',
            access: rightForAllUser,
          }
        ]
      },
      {
        name: 'Sub Category Field',
        icon: KeyboardAltTwoToneIcon,
        link: '/extended-sidebar/management/add-subcategory-field',
        access: rightForAllUser,
        items: [
          {
            name: 'Add Field',
            link: 'management/add-subcategory-field/add-fields',
            access: rightForAdminAndSuperUser,
          },
          {
            name: 'List',
            link: 'management/add-subcategory-field/list',
            access: rightForAllUser
          }
        ]
      },
      {
        name: 'Post',
        icon: PostAddTwoToneIcon,
        link: '/extended-sidebar/management/post',
        access: rightForAllUser,
        items: [
          {
            name: 'Add Post',
            link: 'management/post/add-post',
            access: rightForAdminAndSuperUser,
          },
          {
            name: 'List',
            link: 'management/post/list',
            access: rightForAllUser
          }
        ]
      },
      {
        name: 'Blog Post',
        icon: PostAddTwoToneIcon,
        link: '/extended-sidebar/management/blog-post',
        access: rightForAllUser,
        items: [
          {
            name: 'Add Blog Post',
            link: 'management/blog-post/add-blog-post',
            access: rightForAdminAndSuperUser
          },
          {
            name: 'Blog List',
            link: 'management/blog-post/blog-list',
            access: rightForAllUser,
          }
        ]
      },

      {
        name: 'Innopedia',
        icon: PostAddTwoToneIcon,
        link: '/extended-sidebar/management/innopedia',
        access: rightForAllUser,
        items: [
          {
            name: 'Add Season',
            link: `management/innopedia/add-innopedia`,
            access: rightForAdminAndSuperUser,
          },
          {
            name: 'Season List',
            link: 'management/innopedia/innopedia-list',
            access: rightForAllUser,
          }
        ]
      },
      {
        name: 'Users',
        icon: AccountCircleTwoToneIcon,
        link: '/extended-sidebar/management/users',
        access: rightForAllUser,
        items: [
          {
            name: 'List',
            link: 'management/users/list',
            access: rightForAllUser
          }
        ]
      },
      {
        name: 'Admin',
        icon: SupervisorAccountIcon,
        link: '/extended-sidebar/management/admin',
        access: rightForAdminAndSuperUser,
        items: [
          {
            name: 'List',
            link: 'management/admin/list',
            access: rightForAdminAndSuperUser,
          },
          {
            name: 'Create New User',
            link: 'management/admin/createUser',
            access: rightForOnlySuperUser
          }
        ]
      },
      {
        name: 'Subscribers',
        icon: AssignmentIndTwoToneIcon,
        link: '/extended-sidebar/management/subscribers',
        access: rightForAllUser,
        items: [
          {
            name: 'List',
            link: 'management/subscribers/list',
            access: rightForAllUser,
          }
        ]
      },
      
      {
        name: 'Banner',
        icon: ViewCarouselTwoToneIcon,
        link: '/extended-sidebar/management/banner',
        access: rightForAllUser,
        items: [
          {
            name: 'Add Banner',
            link: 'management/banner/add-banner',
            access: rightForAdminAndSuperUser,
          },
          {
            name: 'List',
            link: 'management/banner/list',
            access: rightForAllUser
          }
        ]
      },
      {
        name: 'Logs',
        icon: TrackChangesIcon,
        link: '/extended-sidebar/management/logs',
        access: rightForOnlySuperUser,
      },
      
    ]
  }
];

export default menuItems;
