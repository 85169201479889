import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Analytics from 'src/content/management/Analytics';
// import NewAnalytics from 'src/content/management/NewAnalytics';
import Block1 from 'src/content/blocks/ChartsSmall';
import Block2 from 'src/content/blocks/ChartsLarge';
import BlogList from 'src/content/management/Blogs';
import AddBlogPost from 'src/content/management/Blogs/addBlogPost';
import PartnerWithUs from 'src/content/management/PartnerWithUs';
import Campaigns from 'src/content/management/Campaigns';
import SubCategoryUpdate from 'src/content/management/SubCategory/updateSubCategory';
import EnnopediaList from 'src/content/management/EnnopediaList';
import AddEnnopedia from 'src/content/management/EnnopediaList/AddEnnopedia';
import RegisterBasic from 'src/content/pages/Auth/Register/Basic';
import Logs from 'src/content/management/Logs';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

// const Users = Loader(lazy(() => import('src/content/management/Users')));
// const SingleUser = Loader(
//   lazy(() => import('src/content/management/Users/single'))
// );

// const AddRole = Loader(
//   lazy(() => import('src/content/management/Role/addRole'))
// );
// const RoleList = Loader(lazy(() => import('src/content/management/Role')));
const AddCategory = Loader(
  lazy(() => import('src/content/management/Category/AddCategory'))
);
const CategoryList = Loader(
  lazy(() => import('src/content/management/Category'))
);
const AddSubCategory = Loader(
  lazy(() => import('src/content/management/SubCategory/addSubCategory'))
);
const SubCategoryList = Loader(
  lazy(() => import('src/content/management/SubCategory'))
);
const AddPost = Loader(
  lazy(() => import('src/content/management/Post/addPost'))
);
const PostList = Loader(lazy(() => import('src/content/management/Post')));
const Users = Loader(
  lazy(() => import('src/content/management/OfficialUsers'))
);
const Subscribers = Loader(
  lazy(() => import('src/content/management/Subscribers'))
);
// const AddVideo = Loader(
//   lazy(() => import('src/content/management/Video/addVideo'))
// );
// const VideoList = Loader(lazy(() => import('src/content/management/Video')));
const AddField = Loader(
  lazy(() => import('src/content/management/SubCategoryField/addField'))
);
const FieldList = Loader(
  lazy(() => import('src/content/management/SubCategoryField'))
);
// const MemberList = Loader(lazy(() => import('src/content/management/Team')));
// const AddMember = Loader(
//   lazy(() => import('src/content/management/Team/addMember'))
// );
const BannerList = Loader(lazy(() => import('src/content/management/Banner')));
const AddBanner = Loader(
  lazy(() => import('src/content/management/Banner/addBanner'))
);
const AddCategoryField = Loader(
  lazy(() => import('src/content/management/CategoryField/addCategoryField'))
);
const CategoryFieldList = Loader(
  lazy(() => import('src/content/management/CategoryField'))
);
const AddCategoryFields = Loader(
  lazy(() => import('src/content/management/CategoryField/addField'))
);
const AddCourseName = Loader(
  lazy(() => import('src/content/management/CategoryField/addCourseName'))
);

const AdminUser = Loader(lazy(()=> import('src/content/management/AdminUser/index')))

const managementRoutes = [
  {
    path: '/',
    element: <Navigate to="post" replace />
  },
  // {
  //   path: 'member',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="list" replace />
  //     },
  //     {
  //       path: '/list',
  //       element: <MemberList />
  //     },
  //     {
  //       path: '/add-member',
  //       element: <AddMember />
  //     }
  //   ]
  // },
  {
    path: 'partner-with-us',
    element: <PartnerWithUs />
  },
  {
    path: 'campaigns',
    element: <Campaigns />
  },
  {
   path: 'logs',
   element: <Logs />
  },
  {
    path: 'analytics',
    children: [
      {
        path: '/',
        element: <Analytics />
      },
      {
        path: '/small-analytics',
        element: <Block1 />
      },
      {
        path: '/large-analytics',
        element: <Block2 />
      }
    ]
  },
  {
    path: 'banner',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: '/list',
        element: <BannerList />
      },
      {
        path: '/add-banner',
        element: <AddBanner />
      }
    ]
  },
  {
    path: 'category',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: '/list',
        element: <CategoryList />
      },
      {
        path: '/add-category',
        element: <AddCategory />
      }
    ]
  },
  {
    path: 'sub-category',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: '/list',
        element: <SubCategoryList />
      },
      {
        path: '/add-sub-category',
        element: <AddSubCategory />
      },
      {
        path: '/update-sub-category',
        element: <SubCategoryUpdate />
      }
    ]
  },
  {
    path: 'post',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: '/list',
        element: <PostList />
      },
      {
        path: '/add-post',
        element: <AddPost />
      }
    ]
  },
  {
    path: 'blog-post',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: '/blog-list',
        element: <BlogList />
      },
      {
        path: '/add-blog-post',
        element: <AddBlogPost />
      }
    ]
  },
 {
  path: 'innopedia',
  children: [
    {
      path: '/',
      element: <Navigate to= 'list' replace />
    },
    {
      path: '/innopedia-list',
      element: <EnnopediaList />
    },
    {
      path: '/add-innopedia',
      element: <AddEnnopedia />
    },
    {
      path: '/add-innopedia: id',
      element: <AddEnnopedia />
    }
  ]
 },
  {
    path: 'add-subcategory-field',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: '/list',
        element: <FieldList />
      },
      {
        path: '/add-fields',
        element: <AddField />
      }
    ]
  },
  {
    path: 'users',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Users />
      }
    ]
  },

  {
    path: 'admin',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
      path: 'createUser',
      element: <RegisterBasic/>
      },
      {
        path: 'list',
        element: <AdminUser />
      },
    ]
  },
  {
    path: 'subscribers',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Subscribers />
      }
    ]
  },
  // {
  //   path: 'videos',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="list" replace />
  //     },
  //     {
  //       path: 'list',
  //       element: <VideoList />
  //     },
  //     {
  //       path: 'add-video',
  //       element: <AddVideo />
  //     }
  //   ]
  // },
  {
    path: 'category-field',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <CategoryFieldList />
      },
      {
        path: 'add-category-field',
        element: <AddCategoryField />
      },
      {
        path: 'add-course-name',
        element: <AddCourseName />
      },
      {
        path: 'add-fields',
        element: <AddCategoryFields />
      },
    ]
  }
  // {
  //   path: 'member',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="list" replace />
  //     },
  //     {
  //       path: 'list',
  //       element: <MemberList />
  //     },
  //     {
  //       path: 'add-member',
  //       element: <AddMember />
  //     }
  //   ]
  // }
];

export default managementRoutes;
