import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';

import useNewBlog from 'src/hooks/useNewBlog';
import { Typography, Grid } from '@mui/material';
import useCategory from 'src/hooks/useCategory';
import useSubCategory from 'src/hooks/useSubCategory';

import Lists from './Lists';

const BlogList = () => {
  const { t } = useTranslation();
  const { blogList, isFetching, fetchNewBlogList } = useNewBlog();
  const { fetchCategoryList } = useCategory();
  const { fetchSubCategoryList } = useSubCategory();
  const controller = new AbortController();
  const isBlogCategory= true;
  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchNewBlogList(controller.signal);
      } catch (error) {
        console.log(error);
      }
    };

    fetchList();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchCategoryList(controller.signal,isBlogCategory);
      } catch (error) {
        console.log(error);
      }
    };

    fetchList();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await fetchSubCategoryList(controller.signal);
      } catch (error) {
        console.log(error);
      }
    };

    fetchList();

    return () => controller.abort();
  }, []);

  return (
    <>
      <Helmet>
        <title>Blog Post</title>
      </Helmet>
      {/* <PageTitleWrapper>
        <Typography variant="h1">{t('Blog Post')}</Typography>
      </PageTitleWrapper> */}
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Lists
            items={blogList.map((item, index) => ({ sNo: index + 1, ...item }))}
            isFetching={isFetching}
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default BlogList;
