import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Box,
  Tooltip,
  Typography,
  Button
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const applyPagination = (items, page, limit) =>
  items.slice(page * limit, page * limit + limit);

const Campaigns = ({ items, exportfetchCampaign }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedItems = useMemo(() => applyPagination(items, page, limit), [items, page, limit]);

  const handldeExportedFunction = async (idx) => {
    const itemIndex = page * limit + idx;
    const payload = {
      campaignKey: items[itemIndex].campaignKey,
      campaignName: items[itemIndex].campaignName,
      year: items[itemIndex].year
    };
    await exportfetchCampaign(payload);
  };

  return (
    <>
      { (
        <>
          {paginatedItems.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("We couldn't find any data")}
              </Typography>
            </>
          ) : (
            <Card>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('S.No')}</TableCell>
                      <TableCell align="center">{t('Name')}</TableCell>
                      <TableCell align="center">{t('Year')}</TableCell>
                      <TableCell align="center">{t('Download')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedItems.map((val, idx) => {
                      const itemIndex = page * limit + idx + 1;
                      return (
                        <TableRow key={itemIndex}>
                          <TableCell align="center">{itemIndex}</TableCell>
                          <TableCell align="center">{val.campaignName}</TableCell>
                          <TableCell align="center">{val.year}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="Download XLS" arrow>
                              <Button
                                onClick={() => handldeExportedFunction(idx)}
                                variant="contained"
                                size="small"
                              >
                                XLS
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={items.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </Card>
          )}
        </>
      )}
    </>
  );
};

Campaigns.propTypes = {
  items: PropTypes.array.isRequired,
  exportfetchCampaign: PropTypes.func.isRequired
};

Campaigns.defaultProps = {
  items: [],
  exportfetchCampaign: () => {}
};

export default React.memo(Campaigns);
