import { createContext, useReducer } from 'react';
import { apiCaller } from 'src/utils/apiCaller';

const initialCategoryFieldState = {
  categoryFieldList: [],
  categoryField: null,
  collegeData:[],
  isFetching: false
};

const CategoryFieldContext = createContext({
  ...initialCategoryFieldState,
  createCategoryField: () => Promise.resolve(),
  fetchCategoryFieldList: () => Promise.resolve(),
  updateCategoryField: () => Promise.resolve(),
  fetchCategoryField: () => Promise.resolve(),
  fetchCategoryFieldCourse : () => Promise.resolve()
});

const ACTION_TYPES = {
  ISFETCHING: 'ISFETCHING',
  FETCH: 'FETCH',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

const handler = {
  ISFETCHING: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  FETCH: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  CREATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  UPDATE: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
};

const reducer = (state, { type, payload }) => {
  return handler[type] ? handler[type](state, payload) : state;
};

export const CategoryFieldContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCategoryFieldState);

  const createCategoryField = async (reqData) => {
    const { success, data } = await apiCaller(
      'post',
      '/category/other-fields',
      reqData
    );
    const categoryField = data ? data.categoryOtherFields : null;
    dispatch({
      type: ACTION_TYPES.CREATE,
      payload: { categoryField }
    });

    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const fetchCategoryFieldList = async (id, signal) => {
    dispatch({
      type: ACTION_TYPES.ISFETCHING,
      payload: { isFetching: true }
    });
    const { data } = await apiCaller(
      'get',
      `/category/other-fields/category/${id}`,
      null,
      null,
      signal
    );
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        categoryFieldList: data ? data.otherFields : [],
        isFetching: false
      }
    });
  };

  const updateCategoryField = async (reqData) => {
    const { success, data } = await apiCaller('put', 'category/other-fields', reqData);
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        categoryField: data ? data.categoryFields : null
      }
    });
    if (success) return Promise.resolve({ success: true });
    return Promise.resolve({ success: false });
  };

  const fetchCategoryField = async (id) => {
    const { data } = await apiCaller('get', `category/other-fields/individual/${id}`);
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        categoryField: data ? data.individualOtherField : null
      }
    });
  }

  const fetchCategoryFieldCourse = async (reqData,signal)=>{
    const {data} = await apiCaller('post','category/other-fields/fetch',
    {courseId:reqData},null,signal);
    dispatch({
      type: ACTION_TYPES.FETCH,
      payload: {
        collegeData: data ? data.allCategoryFields : null
      }
    });
  }
  return (
    <CategoryFieldContext.Provider
      value={{
        ...state,
        createCategoryField,
        fetchCategoryFieldList,
        updateCategoryField,
        fetchCategoryField,
        fetchCategoryFieldCourse,
      }}
    >
      {children}
    </CategoryFieldContext.Provider>
  );
};

export default CategoryFieldContext;
