import React, { useEffect, forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Divider,
  CircularProgress,
  FormControl,
  useTheme,
  Typography,
  Box,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Slide,
  useMediaQuery
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { serialize } from 'object-to-formdata';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import useNewBlog from 'src/hooks/useNewBlog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const SlideUp = forwardRef(function SlideUp(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UpdateBlogPost = ({ open, handleClickClose, post }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const { enqueueSnackbar } = useSnackbar();
  const { updateNewBlog, fetchNewBlogList } = useNewBlog();
  console.log('blog post data', post);

  const blogStatus = (status, message) => {
    enqueueSnackbar(message, {
      variant: status ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: SlideUp
    });
  };

  const { values, handleSubmit, handleChange, isSubmitting, setFieldValue } =
    useFormik({
      initialValues: {
        initialValues: {
          categoryId: null,
          isBlogRecommended: false,
          isBlogFeatured: false,
          // isBlogUpComing: false,
          isBlogTrending: false,
          isBlogLatest: false,
          title: '',
          description: '',
          shortDescription: '',
          bannerImage: null,
          featureImage: null,
          metaTitle: '',
          metaKeyword: '',
          metaDescription: '',
          slug: '',
          readTime: ''
        }
      },
      onSubmit: async (values, { setSubmitting }) => {
        try {
          console.log(values);
          const serializeData = serialize(
            { ...values, blogId: post._id },
            { indices: true }
          );

          const { success,message } = await updateNewBlog(serializeData);
          setSubmitting(false);
          const controller = new AbortController();
          await fetchNewBlogList(controller.signal);
          handleClickClose();
          if (success) {
            blogStatus(true, 'The blog post is successfully updated');
          } else {
            blogStatus(false, `Failed to update blog post ${message}`);
          }
        } catch (error) {
          setSubmitting(false);
          handleClickClose();
          blogStatus(false, error?.data?.message);
        }
      }
    });

  useEffect(() => {
    const properties = [
      'title',
      'description',
      'shortDescription',
      'featureImage',
      'bannerImage',
      'isBlogRecommended',
      'isBlogFeatured',
      // 'isBlogUpComing',
      'isBlogTrending',
      'isBlogLatest',
      'metaTitle',
      'metaDescription',
      'metaKeyword',
      'features',
      'slug',
      'totalLike',
      'totalViews',
      'readTime'
    ];
    if (post) {
      properties.forEach((property) => {
          setFieldValue(property, post[property]);
        }
      );
    }
  }, [post]);

  return (
    <>
      {post && (
        <Dialog
          open={open}
          onClose={handleClickClose}
          fullScreen={matches}
          maxWidth={matches ? 'lg' : false}
        >
          <DialogTitle>Update Blog Post</DialogTitle>
          <Divider />
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                value={values.title}
                label="Blog Title"
                onChange={handleChange}
                name="title"
                type="text"
                variant="outlined"
              />

              {/* Slug */}
              <TextField
                fullWidth
                margin="normal"
                value={values?.slug}
                label="Blog Slug"
                name="slug"
                onChange={(event) => {
                  const temp = event.target.value;
                  setFieldValue('slug', temp?.trim());
                }}
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                margin="normal"
                label="Blog Reading Time"
                name="readTime"
                value={values.readTime}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />

              {/* <TextField
                fullWidth
                margin="normal"
                label="Short Description"
                name="shortDescription"
                value={values.shortDescription}
                onChange={handleChange}
                type="text"
                variant="outlined"
              /> */}

              <TextField
                fullWidth
                margin="normal"
                label="Meta Title"
                name="metaTitle"
                value={values?.metaTitle}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                margin="normal"
                label="Meta Keyword"
                name="metaKeyword"
                value={values?.metaKeyword}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                margin="normal"
                label="Meta Description"
                name="metaDescription"
                value={values?.metaDescription}
                onChange={handleChange}
                type="text"
                variant="outlined"
              />

              {/* <FormControl fullWidth margin="normal">
                <Button
                  color={values.bannerImage ? 'success' : 'primary'}
                  startIcon={
                    values.bannerImage ? <CheckCircleTwoToneIcon /> : null
                  }
                  size="large"
                  variant="outlined"
                  component="label"
                >
                  Select Banner Image
                  <input
                    type="file"
                    hidden
                    name="bannerImage"
                    accept=".jpg, jpeg, .png, .gif"
                    onChange={(event) =>
                      setFieldValue('bannerImage', event.currentTarget.files[0])
                    }
                  />
                </Button>

                <Typography
                  sx={{
                    mt: 1
                  }}
                  variant="caption"
                >
                  (.jpg, jpeg, .png, .gif)
                </Typography>
              </FormControl> */}

              {/* <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                name="totalLike"
                label="Likes"
                type="number"
                value={values.totalLike}
                onChange={handleChange}
              /> */}

              {/* Views */}
              {/* <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Views"
                name="totalViews"
                type="number"
                value={values.totalViews}
                onChange={handleChange}
              /> */}

              <FormControl fullWidth margin="normal">
                <Button
                  color={values.featureImage ? 'success' : 'primary'}
                  startIcon={
                    values.featureImage ? <CheckCircleTwoToneIcon /> : null
                  }
                  size="large"
                  variant="outlined"
                  component="label"
                >
                  Select Featured Image ( size 1366X700 )
                  <input
                    type="file"
                    hidden
                    name="featureImage"
                    accept=".jpg, jpeg, .png, .gif"
                    onChange={(event) =>
                      setFieldValue(
                        'featureImage',
                        event.currentTarget.files[0]
                      )
                    }
                  />
                </Button>
                <Typography
                  sx={{
                    mt: 1
                  }}
                  variant="caption"
                >
                  (.jpg, jpeg, .png, .gif)
                </Typography>
              </FormControl>

              {/* Check box */}

              <Box
                sx={{
                  mt: 2,
                  mb: 1
                }}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogFeatured}
                        onChange={(event) =>
                          setFieldValue('isBlogFeatured', event.target.checked)
                        }
                      />
                    }
                    label="Blog Featured"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogRecommended}
                        onChange={(event) =>
                          setFieldValue(
                            'isBlogRecommended',
                            event.target.checked
                          )
                        }
                      />
                    }
                    label="Blog Recommended"
                  />
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogUpComing}
                        onChange={(event) =>
                          setFieldValue('isBlogUpComing', event.target.checked)
                        }
                      />
                    }
                    label="Blog Upcoming"
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogTrending}
                        onChange={(event) =>
                          setFieldValue('isBlogTrending', event.target.checked)
                        }
                      />
                    }
                    label="Blog Trending"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isBlogLatest}
                        onChange={(event) =>
                          setFieldValue('isBlogLatest', event.target.checked)
                        }
                      />
                    }
                    label="Blog Latest"
                  />
                </FormGroup>
              </Box>

              <FormControl fullWidth margin="normal">
                <Typography variant="body1" mt={-2}>
                  Blog Description
                </Typography>
                <FormControl fullWidth margin="normal">
                  <CKEditor
                    name="blog Body"
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ data });
                      setFieldValue('description', data);
                    }}
                    data={values.description}
                  />
                </FormControl>
              </FormControl>

              <Button
                sx={{
                  mt: 2
                }}
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                size="large"
                fullWidth
              >
                Update Post
              </Button>
            </form>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UpdateBlogPost;
