import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Typography, Grid } from '@mui/material';
import LogList from './list';


const Logs = () => {
  return (
    <>
      <Helmet>
        <title>Logs</title>
      </Helmet>
      <PageTitleWrapper>
        <Typography variant="h1">Logs Detail</Typography>
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <LogList />
        </Grid>
      </Grid>
    </>
  );
};

export default Logs;
